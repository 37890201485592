import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslations } from "hooks";
import styled from "styled-components";
import { rgba } from "polished";
import { Skeleton } from "fiver-react-ui-components";
import LeaderboardRowHeader from "./shared/LeaderboardRowHeader";
import LeaderboardHeaderDatePicker from "./LeaderboardHeaderDatePicker";

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 3;
  top: ${(p) => p.theme.topbarHeight}px;
  left: 0;
  width: 100%;
  background: ${(p) => p.theme.colorCardBackground};
  box-shadow: 0px 0px 10px ${rgba("black", 0.05)};

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    width: ${(props) => props.theme.railWidth};
    left: 50%;
    transform: translateX(-50%);
  }
`;

const StyledListHeaders = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  padding: 0rem 1.3rem;
`;

const StyledProfileRow = styled.div`
  align-self: stretch;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid ${(p) => p.theme.colorBorder};
`;

const StyledLabel = styled.div`
  flex: 0 0 60px;
  text-align: ${(p) => (p.isRight ? "right" : "left")};
`;

const Wrapper = ({ setActivePickemId }) => {
  const [activeIndex, setIndex] = useState();
  const [translations] = useTranslations();

  const {
    activeBet,
    isLeaderboardEmpty,
    allPickems,
    activePickem,
    profile,
    isShowingMyBet,
  } = useSelector((s) => s.leaderboard);

  let row;
  let isLoadingBet = !activeBet?.id;

  if (activeBet?.id) {
    row = {
      rank: activeBet?.rank,
      points: activeBet?.points,
      user: {
        username: profile?.username,
        image: profile?.image,
      },
    };
  } else {
    isLoadingBet = false;
  }

  const isLoading = !activePickem?.id || (activeIndex !== 0 && !activeIndex);
  const pickemLength = allPickems?.length;

  useEffect(() => {
    setIndex(pickemLength - 1);
  }, [pickemLength]);

  return (
    <StyledWrapper>
      <StyledListHeaders>
        <>
          <StyledLabel>{translations.rank}</StyledLabel>
          <LeaderboardHeaderDatePicker
            activeIndex={activeIndex}
            setIndex={setIndex}
            setActivePickemId={setActivePickemId}
            highestIndex={allPickems?.length - 1}
            isLoading={isLoading}
          />
          <StyledLabel isRight>{translations.results}</StyledLabel>
        </>
      </StyledListHeaders>
      {/* <StyledProfileRow>
        {isLoading || isLoadingBet ? (
          <LeaderboardRowHeader isSkeleton isPageHeader />
        ) : (
          <>
            {!isLeaderboardEmpty && (
              <LeaderboardRowHeader isSkeleton={!row} isPageHeader row={row} />
            )}
          </>
        )}
      </StyledProfileRow> */}
    </StyledWrapper>
  );
};

export default Wrapper;
