import axios from "axios";
import { toast } from "react-toastify";
import { createImageCdnLink, handleError } from "utils";
import { setApp } from "../app/actions";

export const SET_SCORES = "scores:SET_SCORES";
export const SET_SCORES_BETS = "scores:SET_SCORES_BETS";
export const UPDATE_PICKS = "scores:UPDATE_PICKS";
export const SET_LOCKED = "scores:SET_LOCKED";
export const SET_SAVING_PICKS = "scores:SET_SAVING_PICKS";
export const SET_HAS_SAVED_PICKS = "scores:SET_HAS_SAVED_PICKS";
export const SET_POPUP_VISIBLE = "scores:SET_POPUP_VISIBLE";
export const SET_SCROLLED_DOWN = "app:SET_SCROLLED_DOWN";

export function setScrolledDown(value) {
  return {
    type: SET_SCROLLED_DOWN,
    payload: value,
  };
}

export function clearScores() {
  return {
    type: SET_SCORES,
    payload: {
      matches: [],
      picks: {
        selections: [],
      },
      isSaving: false,
      isLocked: false,
      hasSavedPicks: false,
      activePickem: undefined,
      activeBet: undefined,
      isContestEmpty: undefined,
      isPlacingBet: undefined,
      isPopupVisible: false,
    },
  };
}

const pickemLoadSuccess = (
  dispatch,
  data,
  currentPicks,
  isContestEmpty = false
) => {
  const pickems = data.data;

  if (pickems && pickems.length > 0) {
    const activePickem = pickems[pickems.length - 1];

    let picks = {
      selections: [],
    };

    if (currentPicks && currentPicks?.selections?.length === 0) {
      for (let q of activePickem.questions) {
        picks.selections.push({
          questionId: q.id,
          homeScore: "",
          awayScore: "",
        });
      }
    } else {
      picks = currentPicks;
    }

    const matches = activePickem.questions.map(({ data, id, status }) => ({
      id: id,
      status: status,
      homeScore: data?.homeScore,
      footballMatchId: data?.id,
      kickoff: data?.kickoff,
      period: data?.period,
      matchTime: data?.matchTime,
      homeTeam: {
        id: data?.homeTeam.id,
        name: data?.homeTeam.name,
        kitImageUrl:
          data?.homeTeam.logo || createImageCdnLink("kit-feyenoord.png"),
        goalForm: data?.homeTeam.stats.lastMatchesScores,
      },
      awayScore: data?.awayScore,
      awayTeam: {
        id: data?.awayTeam.id,
        name: data?.awayTeam.name,
        kitImageUrl:
          data?.awayTeam.logo || createImageCdnLink("kit-feyenoord.png"),
        goalForm: data?.awayTeam.stats.lastMatchesScores,
      },
    }));

    console.log("activePickem", activePickem);

    dispatch({
      type: SET_SCORES,
      payload: isContestEmpty
        ? { isContestEmpty: true }
        : { activePickem, picks, matches },
    });

    axios
      .get(`/pickem-bets?pickem_id=${activePickem?.id}`)
      .then(({ data }) => {
        const activeBetId = data.data[0] && data.data[0].id;

        if (activeBetId) {
          axios
            .get(`/pickem-bets/${activeBetId}`)
            .then(({ data }) => {
              const activeBet = data?.data;
              const { selections } = activeBet;
              let picks = {
                selections: [],
              };

              activeBet?.pickem?.questions.forEach((item, i) => {
                const option = item?.options?.find(
                  (obj) => obj.id === selections[i]
                );

                if (option) {
                  picks.selections.push({
                    questionId: item.id,
                    homeScore: +option.label.substr(0, 1),
                    awayScore: +option.label.substr(2, 1),
                  });
                }
              });

              dispatch({
                type: SET_SCORES_BETS,
                payload: { activeBet: activeBet, picks },
              });
            })
            .catch(({ response }) => {
              handleError(response);
            });
        }
      })
      .catch(({ response }) => {
        handleError(response);
      });
  } else {
    dispatch({
      type: SET_SCORES,
      payload: { isContestEmpty: true },
    });
  }
};

export const getScores = (currentPicks) => (dispatch) => {
  dispatch(clearScores());

  axios
    .get(`/pickems?filter[sport_id]=1&include=questions&include_status=IN_PLAY`)
    .then(({ data }) => {
      if (data.data.length > 0) {
        pickemLoadSuccess(dispatch, data, currentPicks);
      } else {
        // second we try and get APPROVED contests
        axios
          .get(
            `/pickems?filter[sport_id]=1&include=questions&include_status=APPROVED`
          )
          .then(({ data }) => {
            if (data.data.length > 0) {
              pickemLoadSuccess(dispatch, data, currentPicks);
            } else {
              // finally we try and get COMPLETED contests
              axios
                .get(
                  `/pickems?filter[sport_id]=1&include=questions&include_status=COMPLETED`
                )
                .then(({ data }) => {
                  if (data.data.length > 0) {
                    pickemLoadSuccess(dispatch, data, currentPicks);
                  } else {
                    console.log("getting in here ");
                    pickemLoadSuccess(dispatch, data, currentPicks, true);
                  }
                })
                .catch(({ response }) => {
                  handleError(response);
                });
            }
          })
          .catch(({ response }) => {
            handleError(response);
          });
      }
    })
    .catch(({ response }) => {
      handleError(response);
    });
};

export const pusherUpdateMatch = (pusherPickem, matches) => (dispatch) => {
  console.log("pusherUpdateMatch", pusherPickem);

  const question = matches.find((obj) => obj.id === pusherPickem.question.id);
  question.matchTime = pusherPickem.question.data.matchTime;
  question.homeScore = pusherPickem.question.data.homeScore;
  question.awayScore = pusherPickem.question.data.awayScore;
  question.status = pusherPickem.question.status;
  question.period = pusherPickem.question.data.period;

  dispatch({
    type: SET_SCORES,
    payload: {
      matches,
    },
  });
};

export const setSavingPicks = (isSaving) => {
  return {
    type: SET_SAVING_PICKS,
    payload: isSaving,
  };
};

export const setHasSavedPicks = (hasSavedPicks) => {
  return {
    type: SET_HAS_SAVED_PICKS,
    payload: hasSavedPicks,
  };
};

export const setPopupVisble = (value) => {
  return {
    type: SET_POPUP_VISIBLE,
    payload: value,
  };
};

export const updatePicks = (picks) => {
  return {
    type: UPDATE_PICKS,
    payload: picks,
  };
};

export const savePicks = (activePickemId, picks, activePickemName) => (
  dispatch
) => {
  dispatch(setSavingPicks(true));

  // setTimeout(() => {
  //   dispatch(setPopupVisble(true));
  //   dispatch(setSavingPicks(false));
  //   dispatch(setHasSavedPicks(true));
  // }, 1000);

  axios
    .post(`/pickems/${activePickemId}/score-bets`, picks)
    .then(({ data }) => {
      dispatch(setSavingPicks(false));
      dispatch(setHasSavedPicks(true));
      dispatch(getScores());
      dispatch(setPopupVisble(true));

      dispatch(
        setApp({
          showSubmissionPopup: true,
        })
      );

      dispatch({
        type: SET_SCORES,
        payload: {
          uber: data?.data?.uber,
        },
      });
      window.xtremepush(
        "tag",
        "MyPicks.PlaceBet",
        JSON.stringify({
          AppName: process.env.REACT_APP_TITLE,
          ContestName: activePickemName,
        })
      );

      window.xtremepush(
        "event",
        "MyPicks.PlaceBet",
        JSON.stringify({
          AppName: process.env.REACT_APP_TITLE,
          ContestName: activePickemName,
        })
      );
    })
    .catch(({ response }) => {
      dispatch(setSavingPicks(false));
      dispatch(setHasSavedPicks(false));

      if (response?.data?.code === "mobileNumberIsNotVerified") {
        toast.error("Verify Your Phone First");
      } else if (response?.data?.code === "userIsCoolingOff") {
        toast.error("You're still cooling off");
      } else if (response?.data?.code === "identityReferred") {
        toast.error("Identity not verifed yet");
      } else if (response?.data?.code === "eventHasAlreadyStarted") {
        toast.error("Event has already started");
      } else if (response?.data?.code) {
        toast.error(`Error: ${response?.data?.code}`);
      }

      handleError(response);
    });
};

export function setLocked(locked) {
  return {
    type: SET_LOCKED,
    payload: locked,
  };
}
