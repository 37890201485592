import React from "react";
import { withTheme } from "styled-components";
import { NavbarUber } from "fiver-react-ui-components";
import { useTranslations } from "hooks";

const Navbar = ({ theme }) => {
  const [translations] = useTranslations();

  return (
    <NavbarUber
      navbarWidth={theme.railWidth}
      items={[
        {
          to: "/scores",
          text: "Weekly 7",
        },
        {
          to: "/leaderboard",
          text: translations.leaderboard,
        },
        {
          to: "/wallet",
          text: translations.wallet,
        },
        {
          to: "/profile",
          text: translations.profile,
        },
      ]}
    />
  );
};

export default withTheme(Navbar);
