import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { MatIcon, DateTime, Skeleton } from "fiver-react-ui-components";

import { selectPickem } from "./actions";
import { getPickemDateInfo } from "./utils";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledArrowButton = styled.button`
  height: 3rem;

  &:disabled > span {
    color: ${(p) => p.theme.colorSkeleton};
  }
`;

const StyledDateDisplay = styled.div`
  white-space: nowrap;

  & > time {
    line-height: 1;
    display: inline-block;
  }
`;

const Wrapper = ({
  activeIndex,
  setIndex,
  setActivePickemId,
  isLoading,
  highestIndex,
}) => {
  const dispatch = useDispatch();
  const { activePickem, allPickems, isLeaderboardEmpty } = useSelector(
    (s) => s.leaderboard
  );
  const { language } = useSelector((s) => s.auth);

  const handleBackClicked = () => {
    window.scrollTo(0, 0);
    setIndex(activeIndex - 1);
    setActivePickemId(undefined);
    dispatch(selectPickem(allPickems[activeIndex - 1]?.id, true));
  };

  const handleNextClicked = () => {
    window.scrollTo(0, 0);
    setIndex(activeIndex + 1);
    setActivePickemId(undefined);
    dispatch(selectPickem(allPickems[activeIndex + 1]?.id, true));
  };

  const pickemDateInfo = getPickemDateInfo(activePickem);

  const { matchDates, matchMonths, matchesSpanMultipleMonths } = pickemDateInfo;

  return !isLeaderboardEmpty || activePickem?.id ? (
    <StyledWrapper>
      <StyledArrowButton
        disabled={activeIndex === 0 || isLoading}
        onClick={handleBackClicked}
      >
        <MatIcon>keyboard_arrow_left</MatIcon>
      </StyledArrowButton>
      <StyledDateDisplay>
        {!matchDates ? (
          <Skeleton skeletonHeight="16px" />
        ) : (
          <>
            {matchDates?.map((match, index) =>
              index === 0 || index === matchDates.length - 1 ? (
                <span
                  style={{
                    display: "inline-block",
                    marginRight:
                      index === matchDates.length - 1 ? "0.25rem" : "0",
                  }}
                >
                  {match}
                  {matchesSpanMultipleMonths && ` ${matchMonths[index]}`}
                  {!matchesSpanMultipleMonths &&
                    index < matchDates.length - 1 &&
                    "-"}
                  {matchesSpanMultipleMonths &&
                    index < matchDates.length - 1 &&
                    " - "}
                </span>
              ) : null
            )}
            {!matchesSpanMultipleMonths ? (
              <DateTime format="MMM YYYY">{activePickem?.start}</DateTime>
            ) : (
              <DateTime format="YYYY">{activePickem?.start}</DateTime>
            )}
          </>
        )}
      </StyledDateDisplay>
      <StyledArrowButton
        onClick={handleNextClicked}
        disabled={activeIndex === highestIndex || isLoading}
      >
        <MatIcon>keyboard_arrow_right</MatIcon>
      </StyledArrowButton>
    </StyledWrapper>
  ) : null;
};

export default Wrapper;
