import { handleError } from "utils";
import axios from "axios";

export const SET_TRANSLATIONS = "translations:SET_TRANSLATIONS";

export const getTranslations = (language) => async (dispatch, getState) => {
  let translationsRes;
  try {
    translationsRes = await axios.get(`/tenant-localizations/${language}`);
    document
      .getElementById("preloader")
      .classList.remove("translations-loading");
  } catch (error) {
    handleError(error);
  }

  dispatch({
    type: SET_TRANSLATIONS,
    payload: translationsRes?.data?.data,
  });
};
