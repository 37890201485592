import React from "react";
import styled from "styled-components";

import LeaderboardRowContent from "./shared/LeaderboardRowContent";
import LeaderboardRowHeader from "./shared/LeaderboardRowHeader";

const StyledWrapper = styled.div`
  background: ${(p) => p.theme.colorCardBackground};
`;

const LeaderboardListRow = ({ row, active, onRowClicked, children }) => {
  return children ? (
    <StyledWrapper>{children}</StyledWrapper>
  ) : (
    <StyledWrapper>
      <LeaderboardRowHeader
        isActive={row?.betId === active}
        row={row}
        onRowClicked={onRowClicked}
      />
      {row?.betId === active && <LeaderboardRowContent row={row} />}
    </StyledWrapper>
  );
};

export default LeaderboardListRow;
