import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { updatePicks } from "./actions";
import { MatIcon, Skeleton } from "fiver-react-ui-components";

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledMiddle = styled.div`
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  position: relative;
  color: ${(p) => p.theme.colorLabel};
  @media (max-width: 480px) {
    width: 20px;
    min-width: 20px;
    margin: 0 0.15rem;
  }
`;

const StyledLockIcon = styled(MatIcon)`
  color: ${(p) => p.theme.colorLabel};
  font-size: 1.25rem;
`;

const StyledColon = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: space-around;
  transform: translate(-50%, -50%);
`;

const StyledColorCircle = styled.circle`
  fill: ${(p) => p.theme.colorLabel};
`;

const StyledInputWrapper = styled.div`
  position: relative;
`;

const sharedInputStyles = css`
  display: block;
  position: relative;
  color: inherit;
  text-align: center;
  width: 100%;
  max-width: 4rem;
  font-size: 2.75rem;
  height: 5rem;
  line-height: 5rem;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
  border-radius: ${(p) => p.theme.inputBorderRadius};
  font-weight: ${(p) => p.theme.fontWeightNormal};
  background: ${(p) => p.theme.colorInputBackground};

  &:focus + ${StyledMarker} {
    display: block;
  }

  &:focus {
    border-color: ${(p) => p.theme.colorPrimary};
  }

  @media (min-width: ${(p) => p.theme.screenWidthSm}) {
    max-width: 4.75rem;
    width: 4.75rem;
    height: 6rem;
    font-size: 3.25rem;
    line-height: 4rem;
  }
`;

const StyledMarker = styled.div`
  display: none;
  height: 3px;
  width: 75%;
  margin: auto;
  background: ${(p) => p.theme.colorPrimary};
  bottom: 0.5rem;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
`;

const StyledInput = styled.input`
  ${sharedInputStyles};

  color: ${(p) =>
    p.isLocked
      ? p.theme.colorText
      : p.isEdited
      ? p.theme.colorText
      : p.theme.colorText};

  &::placeholder {
    color: ${(p) => p.theme.colorPlaceholder};
  }

  &:disabled {
    -webkit-text-fill-color: ${(p) => p.theme.colorDisabledText};
    background: ${(p) => p.theme.colorDisabledBackground};
  }
`;

const ScoresPicksRowForm = ({
  index,
  isLocked,
  isLoading,
  homeScore,
  awayScore,
  homeEdited,
  awayEdited,
}) => {
  const dispatch = useDispatch();
  const { picks, matches } = useSelector((state) => state.scores);

  const onInputChanged = (e, isHome) => {
    const { value } = e.target;

    if (/^[0-9]*$/.test(value)) {
      if (isHome) {
        picks.selections[index].homeScore = value === "" ? value : +value;
        picks.selections[index].homeEdited = true;
      } else {
        picks.selections[index].awayScore = value === "" ? value : +value;
        picks.selections[index].awayEdited = true;
      }

      dispatch(updatePicks(picks));
    }
  };

  return (
    <StyledWrapper>
      {isLoading ? (
        <Skeleton
          display="block"
          skeletonWidth="3.75rem"
          skeletonHeight="6rem"
        />
      ) : (
        <StyledInputWrapper>
          <StyledInput
            tabIndex={index === 0 ? 1 : index + 1}
            disabled={isLocked}
            value={homeScore || homeScore === 0 ? homeScore : ""}
            onChange={(e) => onInputChanged(e, true)}
            maxLength={1}
            type="tel"
            placeholder="0"
            isEdited={homeEdited}
            isLocked={isLocked}
          />
          <StyledMarker />
        </StyledInputWrapper>
      )}

      <StyledMiddle style={{ opacity: isLoading ? 0 : 1 }}>
        {isLocked && <StyledLockIcon>lock</StyledLockIcon>}
        <StyledColon>
          <svg
            width="6"
            height="6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <StyledColorCircle cx="3" cy="3" r="3" />
          </svg>
          <svg
            width="6"
            height="6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <StyledColorCircle cx="3" cy="3" r="3" />
          </svg>
        </StyledColon>

        {/* {isLocked && matches[index]?.status === "IN_PLAY" && (
          <StyledInPlayIndicator isSuccess>
            <MatIcon>online_prediction</MatIcon>
          </StyledInPlayIndicator>
        )} */}
      </StyledMiddle>

      {isLoading ? (
        <Skeleton
          display="block"
          skeletonWidth="3.75rem"
          skeletonHeight="6rem"
        />
      ) : (
        <StyledInputWrapper>
          <StyledInput
            tabIndex={index === 0 ? 2 : index + 2}
            disabled={isLocked}
            value={awayScore || awayScore === 0 ? awayScore : ""}
            onChange={(e) => onInputChanged(e)}
            maxLength={1}
            type="tel"
            placeholder="0"
            isEdited={awayEdited}
            isLocked={isLocked}
          />

          <StyledMarker />
        </StyledInputWrapper>
      )}
    </StyledWrapper>
  );
};

export default ScoresPicksRowForm;
