import styled from "styled-components";

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  margin-right: 1.5rem;
  height: 56px;
  font-family: ${(p) => p.theme.fontFamilyDefault};

  ${(p) => p.isInactive && "opacity: 0.25;"};
`;

const StyledFlag = styled.img`
  margin-right: 0.75rem;
  height: 40px;
`;

const LanguagePicker = ({ value, onChange }) => {
  return (
    <StyledWrapper>
      <StyledButton
        isInactive={value !== "dutch"}
        disabled={value === "dutch"}
        onClick={() => onChange("dutch")}
      >
        <StyledFlag
          src={`${process.env.REACT_APP_IMAGE_CDN}/flags/Netherlands.png`}
        />
        Dutch
      </StyledButton>
      <StyledButton
        isInactive={value !== "en"}
        disabled={value === "en"}
        onClick={() => onChange("en")}
      >
        <StyledFlag
          src={`${process.env.REACT_APP_IMAGE_CDN}/flags/United-Kingdom.png`}
        />
        English
      </StyledButton>
    </StyledWrapper>
  );
};

export default LanguagePicker;
