import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledWrapper = styled.div`
  position: relative;
  background: ${(p) => p.theme.colorContentBackground};
  ${(p) =>
    !p.hideTopbar &&
    `padding: calc(${p.theme.topbarHeight + p.theme.topbarPadding}px) 0 0`};

  ${(p) => p.isLandingPage && "padding: 0;"};

  ${(p) =>
    p.noTopPadding &&
    `padding: ${p.theme.topbarHeight + p.theme.topbarPadding}px 0 5rem`};

  ${(p) =>
    p.hasPrimaryBackground && `background: ${p.theme.colorCardBackground}`};

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    display: flex;
    flex-direction: column;
    width: ${(props) => props.theme.railWidth};
    flex: 1 1 auto;
    margin: 0 auto;
  }
`;

const Content = ({
  children,
  hideTopbar,
  hasPrimaryBackground,
  isLandingPage,
  noTopPadding,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <StyledWrapper
      noTopPadding={noTopPadding}
      isLandingPage={isLandingPage}
      hideTopbar={hideTopbar}
      hasPrimaryBackground={hasPrimaryBackground}
    >
      {children}
    </StyledWrapper>
  );
};

export default Content;
