import React from "react";
import { createGlobalStyle } from "styled-components";
import { Route } from "react-router-dom";

import Topbar from "components/layout/topbar/Topbar";
import Content from "components/layout/content/Content";
import Navbar from "components/layout/navbar/Navbar";

import { AffiliatePopup } from "@low6dev/react-shared-banners";
import { useSelector } from "react-redux";

const PrimaryBackgroundStyles = createGlobalStyle`
body {
  background: ${(p) => p.theme.colorPrimary};

  @media (min-width: ${(p) => p.theme.screenWidthMd}) {
    background: ${(p) => p.theme.colorBackground};
  }
}
`;

const AppRoute = ({
  theme,
  path,
  exact,
  component,
  hideTopbar,
  hideNav,
  hasPrimaryBackground,
  noTopPadding,
  isLandingPage,
  topbarBurgerMenu,
}) => {
  const { activeBet } = useSelector((s) => s.scores);
  const { showSubmissionPopup } = useSelector((s) => s.app);

  return (
    <Route path={path} exact={exact}>
      {hasPrimaryBackground && <PrimaryBackgroundStyles />}
      {!hideTopbar && <Topbar topbarBurgerMenu={topbarBurgerMenu} />}
      <Content
        noTopPadding={noTopPadding}
        isLandingPage={isLandingPage}
        hideTopbar={hideTopbar}
        hasPrimaryBackground={hasPrimaryBackground}
      >
        {React.createElement(component)}

        {/* <AffiliatePopup
          ctaUrl={process.env.REACT_APP_AFFILIATE_POPUP_URL}
          imageUrl={process.env.REACT_APP_AFFILIATE_POPUP_IMAGE}
          popupPosition={process.env.REACT_APP_AFFILIATE_POPUP_POSITION}
          activeBet={activeBet}
          showSubmissionPopup={showSubmissionPopup}
          appName={process.env.REACT_APP_TITLE}
        /> */}
      </Content>
      {/* {!hideNav && <Navbar />} */}
    </Route>
  );
};

export default AppRoute;
