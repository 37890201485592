import history from "router/history";
import styled from "styled-components";
import { TakeBreak as AppTakeBreak } from "@low6dev/low6-private/dist";
import { handleError } from "utils";
import { LegalFooter } from "components/shared";

const StyledWrapper = styled.div`
  padding-top: 1rem;
`;

const Wrapper = () => {
  return (
    <StyledWrapper>
      <AppTakeBreak
        onBackClicked={() => history.push("/profile")}
        onError={handleError}
      />
      <LegalFooter />
    </StyledWrapper>
  );
};

export default Wrapper;
