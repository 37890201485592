import history from "router/history";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { SelfExclusion as AppSelfExclusion } from "@low6dev/low6-private/dist";
import { handleError } from "utils";
import { setAuth } from "./actions";
import { LegalFooter } from "components/shared";

const StyledWrapper = styled.div`
  padding-top: 1rem;
`;

const Wrapper = () => {
  const dispatch = useDispatch();

  const handleSuccess = () => {
    history.push("/");
    localStorage.removeItem("fiver_react_access_token");
    dispatch(setAuth({ accessToken: undefined }));
  };

  return (
    <StyledWrapper>
      <AppSelfExclusion
        onBackClicked={() => history.push("/profile")}
        onError={handleError}
        onSuccess={handleSuccess}
      />
      <LegalFooter />
    </StyledWrapper>
  );
};

export default Wrapper;
