import React from "react";
import styled from "styled-components";
import { useTranslations } from "hooks";

import history from "router/history";
import { ConfirmPhoneNumber } from "@low6dev/low6-private/dist";
import { handleError } from "utils";
import { LegalFooter } from "components/shared";

const StyledWrapper = styled.div``;

const ConfirmMobile = () => {
  const [translations] = useTranslations();

  const gotoProfile = (isFromSignup) => {
    if (isFromSignup) {
      history.push("/scores");
    } else {
      history.push("/profile");
    }
  };

  return (
    <StyledWrapper>
      <ConfirmPhoneNumber
        translations={translations}
        onError={handleError}
        onSuccess={(isFromSignup) => gotoProfile(isFromSignup)}
        onCancel={gotoProfile}
      />
    </StyledWrapper>
  );
};

export default ConfirmMobile;
