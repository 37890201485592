import React from "react";
import styled from "styled-components";
import { PenceToPounds, Skeleton } from "fiver-react-ui-components";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const StyledLabel = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.375rem;
  font-family: ${(p) => p.theme.fontFamilyDefault};
  text-align: center;
  color: ${(p) => p.theme.colorLabel};
`;

const StyledPrizepool = styled.div`
  font-size: 2rem;
  font-family: ${(p) => p.theme.fontFamily};
  font-weight: ${(p) => p.theme.fontWeightBold};
  color: ${(p) => p.theme.colorText};

  & > span {
    font-weight: ${(p) => p.theme.fontWeightBold};
  }
`;

const PrizeBanner = ({ label, prizepool, isLoading, questionCount }) => {
  return (
    <StyledWrapper>
      {isLoading ? (
        <>
          <Skeleton
            skeletonHeight="20px"
            skeletonWidth="200px"
            margin="5px 0 8px"
          />
          <Skeleton skeletonHeight="28px" skeletonWidth="140px" />
        </>
      ) : (
        <>
          <StyledLabel>{label.replace("4", questionCount)}</StyledLabel>
          <StyledPrizepool>
            {isLoading ? (
              <Skeleton skeletonHeight="40px" skeletonWidth="140px" />
            ) : (
              <>
                {process.env.REACT_APP_CURRENCY_SYMBOL}
                <PenceToPounds hideDecimals>{prizepool}</PenceToPounds>
              </>
            )}
          </StyledPrizepool>
        </>
      )}
    </StyledWrapper>
  );
};

export default PrizeBanner;
