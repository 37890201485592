import React, { useRef } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { useTranslations } from "hooks";
import { Card, MatIcon, FormRowLabel } from "fiver-react-ui-components";

import { toast } from "react-toastify";

const StyledWrapper = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(p) => p.theme.colorLightGrey};
  padding: 1rem;
  border-radius: ${(p) => p.theme.borderRadius};
  color: ${(p) => p.theme.colorLabel};
`;

const StyledInput = styled.input`
  position: absolute;
  left: -9999px;
`;

const StyledText = styled.div`
  margin-left: 0.5rem;
  flex: 1 1 auto;
  min-width: auto;
  text-align: left;
  font-family: ${(p) => p.theme.fontFamilyDefault};
  user-select: none;
`;

const StyledCopyIcon = styled(MatIcon)`
  color: inherit;
  font-size: 22px;
`;

const Wrapper = () => {
  const [translations] = useTranslations();

  const ref = useRef();

  const handleCopyClicked = () => {
    var copyText = ref.current;
    copyText.select();
    document.execCommand("copy");

    toast.success(`${translations.email} ${translations.copied}`);
  };

  return (
    <Card>
      <FormRowLabel>{translations.contactSupport}</FormRowLabel>
      <StyledWrapper onClick={handleCopyClicked}>
        <MatIcon>mail_outline</MatIcon>
        <StyledInput readOnly ref={ref} value="info@score4.nl" />
        <StyledText>info@score4.nl</StyledText>
        <StyledCopyIcon>content_copy</StyledCopyIcon>
      </StyledWrapper>
    </Card>
  );
};

export default Wrapper;
