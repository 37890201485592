import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.span`
  max-width: 100%;
  hyphens: auto;
  word-wrap: break-word;
  font-size: ${(p) => (p.isShort ? "1rem" : "0.75rem")};
`;

const teams = {
  "West Bromwich Albion": "West Brom",
  "Leeds United": "Leeds",
  "Sheffield United": "Sheff Utd",
  "Leicester City": "Leicester",
  "Manchester City": "Man City",
  "Newcastle United": "Newcastle",
  "Tottenham Hotspur": "Spurs",
  "Manchester United": "Man Utd",
  "Brighton and Hove Albion": "Brighton",
  "West Ham United": "West Ham",
  "Wolverhampton Wanderers": "Wolves",
  "Norwich City": "Norwich",
  "Borussia Dortmund": "Dortmund",
};

const Wrapper = ({ children, className, ...rest }) => {
  const isShort = process.env.REACT_APP_CLUB_NAME_LENGTH === "short";

  let friendlyName = children;

  if (teams[friendlyName]) {
    friendlyName = teams[friendlyName];
  }

  return (
    <StyledWrapper isShort={isShort} className={className} {...rest}>
      {friendlyName}
    </StyledWrapper>
  );
};

export default Wrapper;
