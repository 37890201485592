import React from "react";
import { rgba } from "polished";
import styled from "styled-components";
import { createImageCdnLink } from "utils";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const StyledFlag = styled.img`
  width: 2rem;
`;

const StyledText = styled.div`
  margin-left: 0.5rem;
  color: ${rgba("white", 0.96)};
  font-size: 0.875rem;
`;

const Wrapper = () => {
  return (
    <StyledWrapper>
      <StyledFlag src={createImageCdnLink("flags/United-Kingdom.png")} />
      <StyledText>UK Players Only</StyledText>
    </StyledWrapper>
  );
};

export default Wrapper;
