import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { MatIcon, Skeleton } from "fiver-react-ui-components";
import ScoresHelpList from "./ScoresHelpList";

const StyledDropdownWrapper = styled.div`
  overflow: hidden;
  margin: 0 1rem;
  margin-bottom: 1rem;
  max-height: ${(props) => (props.dropdownOpen ? "600px" : "50px")};
  border-radius: ${(p) => p.theme.inputBorderRadius};
  background: ${(props) => props.theme.colorLightGrey};
  color: ${(p) => p.theme.colorText};
  transition: max-height 0.5s;
`;

const StyledDropdownHeader = styled.button`
  font-size: 1rem;
  text-transform: ${(p) => p.theme.specialTextTransform};
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  background: ${(p) => p.theme.colorPrimary};
  color: ${(props) => props.theme.colorPrimaryText};
`;

const StyledDropdownContent = styled.div`
  min-height: 100px;
  padding: 0 1rem;
  font-size: 0.75rem;
  font-weight: ${(p) => p.theme.fontWeightNormal};
`;

const StyledDropdownIcon = styled(MatIcon)`
  transform: rotate(${(props) => (props.dropdownOpen ? "180deg" : "0deg")});
  transition: transform 0.35s;
`;

const ScoresHelp = ({ prizepool }) => {
  const { isNewUser } = useSelector((s) => s.auth);
  const tr = useSelector((s) => s.translations);

  const [dropdownOpen, setDropdownOpen] = useState(isNewUser);
  return prizepool !== undefined ? (
    <StyledDropdownWrapper dropdownOpen={dropdownOpen}>
      <StyledDropdownHeader onClick={() => setDropdownOpen(!dropdownOpen)}>
        <>
          {tr.howToPlay}
          <StyledDropdownIcon dropdownOpen={dropdownOpen}>
            expand_more
          </StyledDropdownIcon>
        </>
      </StyledDropdownHeader>
      <StyledDropdownContent>
        <ScoresHelpList />
      </StyledDropdownContent>
    </StyledDropdownWrapper>
  ) : (
    <Skeleton
      display="block"
      margin="0 1rem 1rem"
      skeletonWidth="calc(100% - 2rem)"
      skeletonHeight="40px"
    />
  );
};

export default ScoresHelp;
