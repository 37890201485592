import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslations } from "hooks";
import Pusher from "pusher-js";
import styled from "styled-components";
import { BlockButton, EmptyScreen } from "fiver-react-ui-components";
import { ScoresTopbar, LoggedInFooter } from "components/shared";

import {
  getAllPickems,
  selectPickem,
  getCurrentMatch,
  getLeaderboard,
  clearLeaderboard,
  pusherUpdateMatch,
  setLoading,
} from "./actions";

import LeaderboardList from "./LeaderboardList";
import LeaderboardRefresh from "./LeaderboardRefresh";
import LeaderboardHeader from "./LeaderboardHeader";
import LeaderboardFooter from "./LeaderboardFooter";

const StyledWrapper = styled.div`
  padding: 3rem 0 3rem;
`;

const StyledButtonWrapper = styled.div`
  padding: 1.5rem;
`;

const Leaderboard = () => {
  const dispatch = useDispatch();

  const {
    loading,
    table,
    activePickem,
    allPickems,
    pageNumber,
    hadBet,
    hasMore,
    isLeaderboardEmpty,
    isShowingMyBet,
    isLoadingMore,
  } = useSelector((state) => state.leaderboard);

  const { language } = useSelector((s) => s.auth);

  const [activePickemId, setActivePickemId] = useState(0);

  const [activeIndex, setIndex] = useState();

  const [translations] = useTranslations();

  const isCompleted = activePickem?.status === "COMPLETED";
  const isInPlay = activePickem?.status === "IN_PLAY";
  const isApproved = activePickem?.status === "APPROVED";
  const isContestClosed = process.env.REACT_APP_IS_CONTEST_CLOSED === "true";

  const handleBackClicked = () => {
    setIndex(activeIndex - 1);
    dispatch(selectPickem(allPickems[activeIndex - 1]?.id));
  };

  const handleNextClicked = () => {
    setIndex(activeIndex + 1);
    dispatch(selectPickem(allPickems[activeIndex + 1]?.id));
  };

  let pusher, channel;

  const pickemLength = allPickems?.length;

  const pusherEventFired = (response) => {
    dispatch(pusherUpdateMatch(response.pickem, activePickem));
  };

  useEffect(() => {
    setIndex(pickemLength - 1);
  }, [pickemLength]);

  useEffect(() => {
    dispatch(getAllPickems());
  }, []);

  useEffect(() => {
    if (activePickem) {
      pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: "eu",
      });

      channel = pusher.subscribe(`Pickem.${activePickem?.id}`);

      if (channel) {
        channel.bind("PickemQuestionUpdated", pusherEventFired);
      }
    }
    return () => {
      return channel && channel?.unbind();
    };
  }, [activePickem]);

  useEffect(() => {
    return () => dispatch(clearLeaderboard());
  }, [clearLeaderboard]);

  const handleLoadMore = () => {
    const newPageNumber = pageNumber + 1;
    dispatch(getLeaderboard(activePickem?.id, newPageNumber));
  };

  return (
    <StyledWrapper>
      {/*<ScoresTopbar*/}
      {/*  isLoading={!table}*/}
      {/*  language={language}*/}
      {/*  activeIndex={activeIndex}*/}
      {/*  highestIndex={allPickems?.length - 1}*/}
      {/*  onBackClicked={handleBackClicked}*/}
      {/*  onNextClicked={handleNextClicked}*/}
      {/*  pickemId={activePickem?.id}*/}
      {/*  kickoff={activePickem?.start}*/}
      {/*/>*/}

      {!activePickem?.id && isLeaderboardEmpty && (
        <EmptyScreen
          padding="4.5rem 0 6rem"
          emptyTitle="Next matches"
          emptySubtitle="Coming very soon!"
          emptyIcon="sports_soccer"
        />
      )}

      {!isContestClosed &&
        table &&
        isLeaderboardEmpty &&
        hadBet === false &&
        isApproved && (
          <EmptyScreen
            emptyIcon="touch_app"
            emptyTitle={translations.leaderboardMakePicksTitle}
            emptySubtitle={translations.leaderboardMakePicksSubtitle}
          />
        )}

      {isContestClosed && table && isLeaderboardEmpty && hadBet === false && (
        <EmptyScreen
          emptyIcon="block"
          emptyTitle="Game closed"
          emptySubtitle="Next game will be available soon"
        />
      )}

      {table && isLeaderboardEmpty && hadBet === false && isCompleted && (
        <EmptyScreen
          emptyTitle={translations.leaderboardNotEnteredTitle}
          emptySubtitle={translations.leaderboardNotEnteredSubtitle}
          emptySubtitle="You did not enter this contest"
        />
      )}

      {!isLeaderboardEmpty || activePickem?.id ? (
        <LeaderboardHeader setActivePickemId={setActivePickemId} />
      ) : null}

      {!isLeaderboardEmpty && (
        <LeaderboardList
          setActivePickemId={setActivePickemId}
          activePickemId={activePickemId}
        />
      )}

      {(isApproved || isContestClosed) && hasMore && !isShowingMyBet && hadBet && (
        <StyledButtonWrapper>
          <BlockButton
            success
            isAccentBackground
            disabled={isLoadingMore}
            isLoading={isLoadingMore}
            onClick={handleLoadMore}
            size="sm"
          >
            Load More
          </BlockButton>
        </StyledButtonWrapper>
      )}

      <LeaderboardFooter
        setIndex={setIndex}
        setActivePickemId={setActivePickemId}
      />

      <LeaderboardRefresh
        activePickemId={activePickemId}
        setActivePickemId={setActivePickemId}
      />

      <LoggedInFooter />
    </StyledWrapper>
  );
};

export default Leaderboard;
