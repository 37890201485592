import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleError } from "utils";
import { getTranslations } from "components/feature/translations/actions";

const useTranslations = (language, shouldLoad) => {
  const dispatch = useDispatch();

  const translations = useSelector((s) => s.translations);

  useEffect(() => {
    if (language && shouldLoad) {
      // localStorage.setItem("activeLanguage", "dutch");
      dispatch(getTranslations(language));
    }
  }, []);

  return [translations];
};

export default useTranslations;
