import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { isAndroid } from "react-device-detect";
import { darken, rgba } from "polished";
import { MatIcon } from "fiver-react-ui-components";
import { createImageCdnLink } from "../../../utils";
import axios from "axios";

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: ${(p) => p.theme.topbarHeight + p.theme.topbarPadding}px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  height: calc(100% - ${(p) => p.theme.topbarHeight + p.theme.topbarPadding}px);
  background: #09090a;
  transform: translateX(${(props) => (props.isOpen ? "0" : "-100%")});
  transition: transform 0.475s;
  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    top: 0;
    height: 100%;
  }

  @media (min-width: ${(props) => props.theme.screenWidthSm}) {
    width: 30%;
  }

  @media (min-width: ${(props) => props.theme.screenWidthLg}) {
    width: 20%;
  }

  @media (min-width: ${(props) => props.theme.screenWidthXl}) {
    width: 15%;
  }
`;

const StyledOverlay = styled.div`
  position: fixed;
  z-index: 9;
  top: ${(p) => p.theme.topbarHeight + p.theme.topbarPadding}px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transition-property: opacity, visibility;
  transition-duration: 0.35s;
  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    top: 0;
    height: 100%;
  }
`;

const StyledLogoRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledCloseButton = styled.button`
  position: absolute;
  top: 0;
  left: 4px;
  color: ${(props) => props.theme.colorPrimaryText};
  width: 56px;
  height: 56px;
  padding: 0;
`;

const StyledLinkList = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-sart;
  width: 100%;
  padding: 0 0 2rem;
`;

const sharedStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${(p) => p.theme.colorText};
  padding: 0 5rem;
  font-size: 1.25rem;
  letter-spacing: 1px;
  white-space: nowrap;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  ${"" /* background: ${rgba("white", 0.05)}; */}
  ${"" /* margin-bottom: 6px; */}
`;

const StyledLink = styled(Link)`
  ${sharedStyles};
`;

const StyledButton = styled.button`
  padding: 0;
  ${sharedStyles};
`;

const StyledLogo = styled.img`
  display: block;
  width: 5.25rem;
  margin: 0 auto 0.625rem;
`;

const StyledAvatar = styled.img`
  margin: 4rem auto 1rem;
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  object-fit: cover;
`;

const StyledText = styled.div`
  font-size: 0.75rem;
  margin-bottom: 3rem;
`;

const StyledUserName = styled.div`
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  line-height: 1.5;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  padding: 0 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledProfileLink = styled(Link)`
  font-size: 0.875rem;
  margin-bottom: 3.875rem;
  color: ${(p) => p.theme.colorSuccess};
`;

const StyledLogoWrapper = styled.a`
  display: block;
  width: 7rem;
  margin: auto;
`;

const TopbarBurger = ({ isOpen, toggleIsOpen, logout }) => {
  const [profile, setProfile] = useState(undefined);

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("fiver_react_access_token")) {
        const res = await axios.get(`/me`);
        setProfile(res?.data?.data);
      }
    })();
  }, [localStorage.getItem("fiver_react_access_token")]);

  useEffect(() => {
    console.log(profile, "kkkkkk");
  }, [profile]);

  const [items] = useState([
    {
      name: "Weekly 7",
      to: "/scores",
      icon: "format_list_numbered",
    },
    {
      name: "Leaderboard",
      to: "/leaderboard",
      icon: "emoji_events",
    },
    {
      name: "Profile",
      to: "/profile",
      icon: "account_balance_wallet",
    },
    {
      name: "Wallet",
      to: "/wallet",
      icon: "account_balance_wallet",
    },
    // ...(process.env.REACT_APP_PRIZE_DRAW
    //   ? [
    //       {
    //         name: "Prize Draw",
    //         icon: "",
    //         onClick: () => {
    //           window.open("https://winme.tv");
    //         },
    //       },
    //     ]
    //   : []),
    // {
    //   name: "Profile",
    //   to: "/profile",
    //   icon: "person",
    // },
    // {
    //   name: "Logout",
    //   onClick: logout,
    //   icon: "exit_to_app",
    // },
  ]);

  const handleButtonClick = (onClick) => {
    toggleIsOpen(false);
    onClick();
  };

  return (
    <>
      <StyledOverlay onClick={() => toggleIsOpen(false)} isOpen={isOpen} />
      <StyledWrapper toggleIsOpen={toggleIsOpen} isOpen={isOpen}>
        <StyledLogoRow>
          <StyledAvatar src={profile?.image} />
          <StyledUserName>{profile?.username}</StyledUserName>
          <StyledProfileLink to="/profile" onClick={() => toggleIsOpen(false)}>
            View Profile
          </StyledProfileLink>
          {/*<StyledCloseButton onClick={() => toggleIsOpen(false)}>*/}
          {/*  <MatIcon size="50px">close</MatIcon>*/}
          {/*</StyledCloseButton>*/}
          {/*<LogoBadge height="56px" width="auto" />*/}
        </StyledLogoRow>
        <StyledLinkList>
          {items.map(({ name, to, icon, onClick }) =>
            to ? (
              <StyledLink
                key={name}
                onClick={() => toggleIsOpen(false)}
                to={to}
              >
                {name}
              </StyledLink>
            ) : (
              <StyledButton
                key={name}
                onClick={() => handleButtonClick(onClick)}
              >
                {name}
              </StyledButton>
            )
          )}
        </StyledLinkList>
        {process.env.REACT_APP_IS_UBER === "true" && (
          <>
            <StyledLogoWrapper
              target="_blank"
              href={
                !isAndroid
                  ? "https://apps.apple.com/us/app/uber-eats-food-delivery/id1058959277"
                  : "https://play.google.com/store/apps/details?id=com.ubercab.eats"
              }
            >
              <StyledLogo src={createImageCdnLink("UberEatsLogo.png")} />
            </StyledLogoWrapper>

            <StyledText>Open UberEats</StyledText>
          </>
        )}
      </StyledWrapper>
    </>
  );
};

export default TopbarBurger;
