import React from "react";
import styled from "styled-components";
import { createImageCdnLink } from "utils";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 1.25rem;
`;

const StyledImage = styled.img`
  width: 70%;
`;

const ScoresBanner = ({ bannerUrl }) => {
  return (
    <StyledWrapper>
      <StyledImage src={createImageCdnLink(bannerUrl)} />
    </StyledWrapper>
  );
};

export default ScoresBanner;
