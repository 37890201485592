import React, { useState } from "react";
import styled, { ThemeContext } from "styled-components";

import ScoresPicksRowInfoBar from "./ScoresPicksRowInfoBar";
import ScoresPicksRowForm from "./ScoresPicksRowForm";

import { Skeleton, MatIcon, DateTime } from "fiver-react-ui-components";
import { ClubName } from "components/shared";
import { useContext } from "react";
import { rgba } from "polished";
import ScoresTipster from "./ScoresTipster";
import { updatePicks } from "./actions";
import { useDispatch, useSelector } from "react-redux";

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 0.75rem 0.35rem;
  background: ${(p) => p.theme.colorCardBackground};
`;

const StyledEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 25%;
  min-width: 25%;
  max-width: 25%;
  padding: 0 0.25rem;
  @media (max-width: 480px) {
    padding: 0;
    flex: 0 0 30%;
    min-width: 30%;
    max-width: 30%;
  }
`;

const StyledMiddle = styled.div`
  flex: 1 1 auto;
  min-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledClub = styled.div`
  max-width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledClubKit = styled.img`
  width: 64px;
`;

const StyledClubName = styled(ClubName)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  line-height: 1.2;
  text-transform: ${(p) => p.theme.specialTextTransform};
  text-align: center;
  font-family: ${(p) => p.theme.fontFamily};
  font-weight: ${(p) => p.theme.fontWeightBold};
`;

const StyledClubForm = styled.div`
  color: ${(p) => p.theme.colorLabel};
  font-size: 0.9375rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 0.5rem;
`;

const StyledBubble = styled.div`
  color: ${(p) => p.theme.colorText};
  background: ${(p) => p.color};
  line-height: 0.875rem;
  font-size: 0.5rem;
  width: 0.875rem;
  height: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  margin-bottom: 0.35rem;
  &:not(:last-child) {
    margin-right: 2px;
  }
`;

const StyledAnswerIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  color: white;
  background: ${(p) => p.cardColor};
  z-index: 1;
  & > span {
    font-size: 20px;
  }
`;

const StyledIcon = styled(MatIcon)`
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  top: calc(94%);
  color: ${(p) => p.theme.colorPrimary};
  font-size: 1rem;
`;

const StyledMatchDate = styled.div`
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 6px;
  border-radius: 20px;
  background: ${(p) => p.theme.colorLightGrey};
  color: ${(p) => p.theme.colorLabel};
  z-index: 1;
  font-size: 0.625rem;
`;

const ScoresPicksRow = ({
  index,
  isCorrect,
  isLocked,
  isLoading,
  cardColor,
  homeTeam,
  awayTeam,
  homeScore,
  awayScore,
  homeEdited,
  awayEdited,
  liveHomeScore,
  liveAwayScore,
  minute,
  kickoff,
  status,
  tipsterMock,
  period,
}) => {
  const theme = useContext(ThemeContext);
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();
  const { picks } = useSelector((state) => state.scores);

  const getScoringForm = (goalForm, isHome) => {
    return goalForm
      ? goalForm.map((obj, index) => (
          <StyledBubble
            color={
              obj.goalsScored === obj.goalsConceded
                ? theme.colorLabel
                : obj.goalsScored > obj.goalsConceded
                ? theme.colorSuccess
                : theme.colorDanger
            }
          >
            {obj.goalsScored}
            {index === 0 && <StyledIcon>expand_less</StyledIcon>}
          </StyledBubble>
        ))
      : "";
  };

  const isInPlay = status === "IN_PLAY";
  const isCompleted = status === "COMPLETED";

  return isLoading ? (
    <StyledWrapper>
      <StyledEnd>
        <StyledClub>
          <Skeleton
            display="block"
            skeletonWidth="50px"
            skeletonHeight="55px"
            margin="0 0 0.5rem"
          />
          <Skeleton
            display="block"
            skeletonWidth="35px"
            skeletonHeight="16px"
            skeletonBorderRadius="3px"
            margin="0 0 0.325rem"
          />
        </StyledClub>
      </StyledEnd>
      <StyledMiddle>
        <ScoresPicksRowForm
          index={index}
          isLocked={isLocked}
          isLoading={isLoading}
          homeScore={homeScore}
          awayScore={awayScore}
          homeEdited={homeEdited}
          awayEdited={awayEdited}
        />
      </StyledMiddle>
      <StyledEnd>
        <StyledClub>
          <Skeleton
            display="block"
            skeletonWidth="50px"
            skeletonHeight="55px"
            margin="0 0 0.5rem"
          />
          <Skeleton
            display="block"
            skeletonWidth="35px"
            skeletonHeight="16px"
            skeletonBorderRadius="3px"
            margin="0 0 0.325rem"
          />
        </StyledClub>
      </StyledEnd>
    </StyledWrapper>
  ) : (
    <>
      {isInPlay || isCompleted ? (
        <StyledAnswerIndicator cardColor={cardColor}>
          <MatIcon>{isCorrect ? "check" : "close"}</MatIcon>
        </StyledAnswerIndicator>
      ) : null}

      <StyledMatchDate>
        <DateTime format="MMM DD - h:mm a">{kickoff}</DateTime>
      </StyledMatchDate>

      <StyledWrapper
        onClick={(e) => {
          if (!isLocked && process.env.REACT_APP_SHOW_TIPSTERS === "true") {
            setShowPopup((prev) => !prev);
          }
        }}
        isCompleted={isCompleted}
        isInPlay={isInPlay}
      >
        <StyledEnd>
          <StyledClub>
            <StyledClubKit src={homeTeam.kitImageUrl} />
            {!isInPlay && !isCompleted && (
              <StyledClubName>{homeTeam.name}</StyledClubName>
            )}
            {process.env.REACT_APP_HIDE_SCORING_FORM === "false" && (
              <StyledClubForm>
                {getScoringForm(homeTeam.goalForm, "home")}
              </StyledClubForm>
            )}
          </StyledClub>
        </StyledEnd>
        <StyledMiddle>
          <ScoresPicksRowForm
            index={index}
            isLocked={isLocked}
            homeScore={homeScore}
            awayScore={awayScore}
            homeEdited={homeEdited}
            awayEdited={awayEdited}
          />
        </StyledMiddle>
        <StyledEnd>
          <StyledClub>
            <StyledClubKit src={awayTeam.kitImageUrl} />
            {!isInPlay && !isCompleted && (
              <StyledClubName>{awayTeam.name}</StyledClubName>
            )}
            {process.env.REACT_APP_HIDE_SCORING_FORM === "false" && (
              <StyledClubForm>
                {getScoringForm(awayTeam.goalForm, "away")}
              </StyledClubForm>
            )}
          </StyledClub>
        </StyledEnd>
      </StyledWrapper>
      {/* <ScoresPicksRowInfoBar
        isCompleted={false}
        isCorrect={false}
        kickoff={kickoff}
        minute={21}
        isInPlay={true}
        liveHomeScore={liveHomeScore}
        liveAwayScore={liveAwayScore}
        homeTeam={homeTeam.name}
        awayTeam={awayTeam.name}
        period={"FirstHalf"}
      /> */}
      {/* <ScoresPicksRowInfoBar
        isCompleted={isCompleted}
        isCorrect={isCorrect}
        kickoff={kickoff}
        minute={minute}
        isInPlay={isInPlay}
        liveHomeScore={liveHomeScore}
        liveAwayScore={liveAwayScore}
        homeTeam={homeTeam.name}
        awayTeam={awayTeam.name}
        period={period}
      /> */}
    </>
  );
};

export default ScoresPicksRow;
