import { SET_TRANSLATIONS } from "./actions";

const initialState = {};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_TRANSLATIONS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
