import React from "react";
import styled from "styled-components";
import history from "router/history";
import { ForgotPassword as AppForgotPassword } from "@low6dev/low6-private/dist";
import { handleError, createImageCdnLink } from "utils";
import { LegalFooter } from "components/shared";

const StyledWrapper = styled.div`
  padding-top: 1rem;
`;

const Wrapper = () => {
  const mainLogo = createImageCdnLink(
    process.env.REACT_APP_LOGIN_LOGO || process.env.REACT_APP_MAIN_LOGO
  );

  return (
    <StyledWrapper>
      <AppForgotPassword
        onSuccess={() => console.log("email sent")}
        onError={handleError}
        appIconUrl={mainLogo}
      />
      <LegalFooter />
    </StyledWrapper>
  );
};

export default Wrapper;
