import { OPEN_DRAWER, CLEAR_DRAWER } from "./actions";

const initialState = {
  isOpen: false,
  useAssetsCdn: undefined,
  pageSlug: undefined,
  pageTitle: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case OPEN_DRAWER:
      return {
        ...state,
        ...payload,
      };
    case CLEAR_DRAWER:
      return {
        ...payload,
      };
    default:
      return state;
  }
}
