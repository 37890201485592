import history from "router/history";

const createFileName = (fileName) =>
  `${process.env.REACT_APP_IMAGE_CDN}${fileName}`;

export function createImageCdnLink(fileName) {
  return `${process.env.REACT_APP_IMAGE_CDN}${fileName}`;
}

export function numberWithCommas(x) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
}

export function handleError(response, dontRedirect) {
  if (process.env.NODE_ENV === "development") {
    console.log("handleError", response);
  }
  if (response) {
    const { status, message } = response;
    if (status === 401 || status === 408) {
      if (!dontRedirect) {
        history.push("/");
      }

      if (localStorage.getItem("fiver_react_access_token")) {
        localStorage.removeItem("fiver_react_access_token");
      }
    }
  }
}
