import styled, { css } from "styled-components";
import { rgba } from "polished";
import { LegalFooter } from "components/shared";
import { useTranslations } from "hooks";
import { Link } from "react-router-dom";
import { createImageCdnLink } from "utils";
import { openDrawer } from "../../layout/drawer/actions";
import { useDispatch } from "react-redux";
import { useCheckLogin } from "hooks";

import LandingUkOnly from "./LandingUkOnly";
import { useEffect } from "react";

const StyledWrapper = styled.div``;

const StyledTopBanner = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 7rem 0 2rem;
  background: ${(p) => p.theme.colorCardBackground};

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    padding: 7rem 0 6rem;
  }
`;

const StyledTopbarImage = styled.img`
  position: relative;
  z-index: 1;
  height: 80px;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    height: 100px;
  }
`;

const StyledMiddleBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 3rem;
  margin-bottom: 1rem;
  background: ${(props) => props.theme.colorDark};
`;

const StyledMiddleBannerLogo = styled.img`
  width: 56px;
  margin-right: 1rem;
  border-radius: 50%;
  border: 2px solid white;
`;

const StyledMiddleBannerText = styled.div`
  color: ${(props) => props.theme.colorDarkText};
  line-height: 1.25;
  text-align: center;
  width: 240px;
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
`;

const StyledButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    width: 260px;
  }
`;

const sharedButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 72px;
  line-height: 1;
  font-weight: 600;
  font-size: 1.25rem;
  border-radius: ${(p) => p.theme.inputBorderRadius};
  border: 4px solid ${(props) => props.theme.colorPrimaryText};
`;

const StyledButton = styled(Link)`
  ${sharedButtonStyles};
  color: ${(props) =>
    props.primary
      ? props.theme.colorCardBackground
      : props.theme.colorPrimaryText};
  background: ${(props) =>
    props.primary ? props.theme.colorPrimaryText : "transparent"};
`;

const StyledSmallPrint = styled.div`
  color: ${rgba("white", 0.8)};
  font-size: 0.6rem;
  line-height: 1.5;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 3rem;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    padding: 0 3rem;
  }
`;

const Wrapper = () => {
  useEffect(() => {
    window.xtremepush(
      "tag",
      "landing_page",
      JSON.stringify({
        AppName: process.env.REACT_APP_TITLE,
      })
    );
    window.xtremepush(
      "tag",
      "web_app_opened",
      JSON.stringify({
        AppName: process.env.REACT_APP_TITLE,
      })
    );
  }, []);

  useCheckLogin();

  const [translations] = useTranslations("en");
  const dispatch = useDispatch();

  const handlePageButtonClicked = (pageSlug, pageTitle, useAssetsCdn) => {
    dispatch(openDrawer(pageSlug, pageTitle, useAssetsCdn));
  };

  const getMainImage = createImageCdnLink(
    process.env.REACT_APP_LANDING_MAIN_LOGO
  );
  const getBannerImage = createImageCdnLink(
    process.env.REACT_APP_LANDING_BANNER_LOGO
  );

  return (
    <>
      <StyledWrapper>
        <StyledTopBanner>
          <StyledTopbarImage src={getMainImage} />
        </StyledTopBanner>
        <StyledMiddleBanner>
          <StyledMiddleBannerLogo src={getBannerImage} />
          <StyledMiddleBannerText>
            Predict the scores of 7 matches to win £1,000,000!
          </StyledMiddleBannerText>
        </StyledMiddleBanner>
        <LandingUkOnly />
        <StyledButtonsWrapper>
          <StyledButtons>
            <StyledButton
              to="/signup"
              style={{ marginBottom: "1rem" }}
              primary={1}
            >
              Sign up
            </StyledButton>
            <StyledButton to="/login">Login</StyledButton>
          </StyledButtons>
        </StyledButtonsWrapper>
        {process.env.REACT_APP_CONTEST_CLOSE_DATE && (
          <StyledSmallPrint>
            18+ UK Only. Entry closes earlier of{" "}
            {process.env.REACT_APP_CONTEST_CLOSE_TIME} BST{" "}
            {process.env.REACT_APP_CONTEST_CLOSE_DATE} or{" "}
            {process.env.REACT_APP_CONTEST_MAX_ENTRIES} entries. Jackpot split
            if >1 winner. Full terms apply. Further terms apply to Uber Eats
            Voucher - expiry 23:59 on {process.env.REACT_APP_CONTEST_CLOSE_DATE}
            . Contest managed by Low6.
          </StyledSmallPrint>
        )}
      </StyledWrapper>

      <LegalFooter isDark />
    </>
  );
};

export default Wrapper;
