import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleError } from "utils";
import Pusher from "pusher-js";
import styled from "styled-components";
import {
  getScores,
  pusherUpdateMatch,
  setPopupVisble,
  clearScores,
} from "./actions";
import { MatIcon, EmptyScreen } from "fiver-react-ui-components";
import moment from "moment";

import ScoresBanner from "./ScoresBanner";
import ScoresPicks from "./ScoresPicks";
// import ScoresAdvert from "./ScoresAdvert";
import ScoresBetNow from "./ScoresBetNow";
import ScoresHelp from "./ScoresHelp";
import ScoresShareButton from "./ScoresShareButton";

import { LoggedInFooter, PrizeBanner } from "components/shared";
import Weekly7PopupBanner from "components/shared/banners/Weekly7PopupBanner";
import { UberGameBanner } from "components/shared";
import axios from "axios";
import { createImageCdnLink } from "utils";

import ScoresFloatingHeaderVerify from "./ScoresFloatingHeaderVerify";
import ScoresFloatingHeaderCoolingOff from "./ScoresFloatingHeaderCoolingOff";
import ScoresFloatingHeaderIDCheck from "./ScoresFloatingHeaderIDCheck";
import ScoresFloatingHeaderGameClosed from "./ScoresFloatingHeaderGameClosed";

const StyledWrapper = styled.div`
  padding: 1.5rem 0 0;
`;

const StyledBigLogo = styled.img`
  width: 60%;
  display: block;
  margin: 0 auto 0.875rem;
`;

const StyledBanner = styled.img`
  width: 100%;
  display: block;
  margin: 0 0 1rem;
`;

const StyledIndicatorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 1rem 1rem;
`;

const StyledLeft = styled.div`
  flex: 0 0 50%;
  display: flex;
`;

const StyledIndicator = styled.div`
  padding: 0.5rem;
  background: ${(p) => p.theme.colorLightGrey};
  margin-right: 1rem;
  font-size: 1.75rem;
  display: flex;
  align-items: center;
`;

const StyledBadge = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(p) =>
    p.isCorrect ? p.theme.colorSuccess : p.theme.colorDanger};
  border-radius: 50%;
  color: #fff;
  margin-right: 1rem;
`;

const StyledLogoWrapper = styled.div`
  position: relative;
`;

const Scores = () => {
  const dispatch = useDispatch();

  const translations = useSelector((s) => s.translations);

  const [profile, setProfile] = useState({});
  const [coolingOff, setCoolingOff] = useState();

  useEffect(() => {
    (async () => {
      const res = await axios.get(`/me`);
      setProfile(res?.data?.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get("/cooling-off");
        setCoolingOff(res?.data?.data);
      } catch ({ response }) {
        handleError(response);
      }
    })();
  }, []);

  const { mobileNumberVerified, identityPassed } = profile;

  const {
    picks,
    matches,
    activePickem,
    activeBet,
    isPopupVisible,
    uber,
    isContestEmpty,
  } = useSelector((state) => state.scores);

  const { language } = useSelector((s) => s.auth);

  const isCoolingOff = coolingOff?.duration > 0;

  const isContestClosed = process.env.REACT_APP_IS_CONTEST_CLOSED === "true";

  const isLocked =
    isContestClosed ||
    isCoolingOff ||
    !mobileNumberVerified ||
    !identityPassed ||
    activePickem?.status === "IN_PLAY" ||
    activePickem?.status === "COMPLETED" ||
    (activeBet &&
      activeBet?.selections.length === activePickem?.questions.length)
      ? true
      : false;

  const isBetPlaced =
    activeBet && activeBet?.selections.length === activePickem?.questions.length
      ? true
      : false;

  const arePicksValid =
    picks?.selections.length > 0 &&
    !picks?.selections?.some(
      (obj) => obj.homeScore === "" || obj.awayScore === ""
    );

  let pusher, channel;

  const pusherEventFired = (response) => {
    dispatch(pusherUpdateMatch(response.pickem, matches));
  };

  useEffect(() => {
    if (activePickem && activePickem?.status === "IN_PLAY") {
      pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: "eu",
        enabledTransports: ["ws"],
      });

      channel = pusher.subscribe(`Pickem.${activePickem?.id}`);

      if (channel) {
        channel.bind("PickemQuestionUpdated", pusherEventFired);
      }
    }
    return () => {
      return channel && channel?.unbind();
    };
  }, [activePickem]);

  useEffect(() => {
    dispatch(getScores(picks));
  }, []);

  useEffect(() => {
    return () => dispatch(clearScores());
  }, [clearScores]);

  const countCorrect = () => {
    return matches.reduce((acc, match, index, arr) => {
      const homeScorePrediction = picks?.selections[index]?.homeScore;
      const awayScorePrediction = picks?.selections[index]?.awayScore;
      if (
        match.homeScore === homeScorePrediction &&
        match.awayScore === awayScorePrediction &&
        (match.status === "COMPLETED" || match.status === "IN_PLAY")
      ) {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
  };

  const countIncorrect = () => {
    return matches.reduce((acc, match, index, arr) => {
      const homeScorePrediction = picks?.selections[index]?.homeScore;
      const awayScorePrediction = picks?.selections[index]?.awayScore;
      if (
        (match.homeScore !== homeScorePrediction ||
          match.awayScore !== awayScorePrediction) &&
        (match.status === "COMPLETED" || match.status === "IN_PLAY")
      ) {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
  };

  const correctAnswer = countCorrect();
  const incorrectAnswer = countIncorrect();

  return (
    <StyledWrapper>
      {isContestEmpty === true ? (
        <EmptyScreen
          emptyTitle="Next matches"
          emptySubtitle="Coming very soon!"
          emptyIcon="sports_soccer"
        />
      ) : (
        // activePickem && isContestClosed && !isBetPlaced ? (
        //   <EmptyScreen
        //     emptyTitle="Game closed"
        //     emptySubtitle="Next game will be available soon"
        //     emptyIcon="block"
        //   />
        // ) : (
        <>
          {mobileNumberVerified === false && <ScoresFloatingHeaderVerify />}
          {coolingOff === true && <ScoresFloatingHeaderCoolingOff />}
          {identityPassed === false && <ScoresFloatingHeaderIDCheck />}
          {isContestClosed === true && isBetPlaced === false && (
            <ScoresFloatingHeaderGameClosed />
          )}

          {/* {process.env.REACT_APP_BIG_CONTEST_LOGO && (
            <StyledLogoWrapper>
              {!isContestClosed && process.env.REACT_APP_IS_UBER === "true" && (
                <ScoresShareButton />
              )}
              <StyledBigLogo
                src={createImageCdnLink(process.env.REACT_APP_BIG_CONTEST_LOGO)}
              />
            </StyledLogoWrapper>
          )} */}

          {/* {process.env.REACT_APP_GAME_BANNER && (
            <ScoresBanner bannerUrl={process.env.REACT_APP_GAME_BANNER} />
          )} */}

          <PrizeBanner
            isLoading={!activePickem}
            label={translations.gameSlogan || "Guess 4 Scores To Win"}
            prizepool={activePickem?.prizeFund}
            questionCount={activePickem?.questions?.length}
          />

          {/*<ScoresHelp prizepool={activePickem?.prizeFund} />*/}

          {process.env.REACT_APP_IS_UBER === "true" && <UberGameBanner />}

          {/* {process.env.REACT_APP_TOP_BANNER && (
          <StyledBanner
            src={createImageCdnLink(process.env.REACT_APP_TOP_BANNER)}
          />
        )} */}

          {!isContestClosed &&
          isBetPlaced &&
          (activePickem?.status === "IN_PLAY" ||
            activePickem?.status === "COMPLETED") ? (
            <StyledIndicatorWrapper>
              <StyledLeft>
                <StyledIndicator>
                  <StyledBadge isCorrect>
                    <MatIcon>check</MatIcon>
                  </StyledBadge>
                  {correctAnswer}
                </StyledIndicator>
                <StyledIndicator>
                  <StyledBadge>
                    <MatIcon>close</MatIcon>
                  </StyledBadge>
                  {incorrectAnswer}
                </StyledIndicator>
              </StyledLeft>
            </StyledIndicatorWrapper>
          ) : null}

          <ScoresPicks
            isLocked={isLocked}
            showTipster={
              !isBetPlaced && process.env.REACT_APP_SHOW_TIPSTERS !== "false"
            }
          />

          {/* {process.env.REACT_APP_BOTTOM_BANNER && (
            <StyledBanner
              src={createImageCdnLink(process.env.REACT_APP_BOTTOM_BANNER)}
            />
          )} */}

          {/* {process.env.REACT_APP_IS_UBER === "true" && (
            <Weekly7PopupBanner
              isVisible={
                isPopupVisible && uber && uber?.status && !profile?.nonUkUser
              }
              // isVisible={true}
              onOverlayClicked={() => dispatch(setPopupVisble(false))}
              uberUrl={uber?.code?.link}
              textGreeting={`${
                profile?.firstName
                  ? `Congrats, ${profile?.firstName}!`
                  : "Congrats!"
              }`}
              discount={process.env.REACT_APP_VOUCHER_PRIZE_AMOUNT}
              expired={`expires 11:59pm ${moment(uber?.ends_at).format(
                "DD/MM/YY"
              )}. Terms apply.`}
            />
          )} */}

          <ScoresBetNow isOpen={arePicksValid} isLocked={isLocked} />
        </>
      )}

      {/*<ScoresTopbar kickoff={activePickem?.start} language={language} />*/}

      <LoggedInFooter />
    </StyledWrapper>
  );
};

export default Scores;
