export const OPEN_DRAWER = "drawer:OPEN_DRAWER";
export const CLEAR_DRAWER = "drawer:CLEAR_DRAWER";

export function openDrawer(pageSlug, pageTitle, useAssetsCdn) {
  return {
    type: OPEN_DRAWER,
    payload: {
      isOpen: true,
      useAssetsCdn,
      pageSlug,
      pageTitle,
    },
  };
}

export function clearDrawer() {
  return {
    type: CLEAR_DRAWER,
    payload: {
      isOpen: false,
      useAssetsCdn: undefined,
      pageSlug: undefined,
      pageTitle: undefined,
    },
  };
}
