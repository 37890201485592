import React from "react";
import history from "router/history";
import { Signup as AppSignup } from "@low6dev/react-feature-signup/dist";
import { handleError } from "utils";
import { useDispatch } from "react-redux";
import { LegalFooter } from "components/shared";
import { openDrawer } from "components/layout/drawer/actions";
import { useTranslations, useCheckLogin } from "hooks";

const Signup = () => {
  useCheckLogin();

  const dispatch = useDispatch();

  const [translations] = useTranslations();

  const handlePageButtonClicked = (pageSlug, pageTitle, useAssetsCdn) => {
    dispatch(openDrawer(pageSlug, pageTitle, useAssetsCdn));
  };

  const handleSuccess = () => {
    history.push("/confirm-phone?signup=true");
  };

  return (
    <>
      <AppSignup
        onPageButtonClicked={handlePageButtonClicked}
        onSuccess={handleSuccess}
        onError={handleError}
        privacySlug="privacy"
        termsSlug="terms"
        isNonUkForced={false}
        hideDataShareCheckbox={true}
        hideMobileNumberCheckbox={true}
        hideMobileNumberAlert
        hasTextboxDatePicker={
          process.env.REACT_APP_SIGNUP_HAS_TEXTBOX_DATE_PICKER
        }
        forcedCountryCode={process.env.REACT_APP_FORCED_COUNTRY_CODE}
      />
      <LegalFooter />
    </>
  );
};

export default Signup;
