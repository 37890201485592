import { SET_AUTH } from "./actions";

const initialState = {
  accessToken: undefined,
  brand: "ad",
  language: "en",
  isNewUser: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_AUTH:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
