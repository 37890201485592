import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Wallet as AppWallet,
  WalletTickets,
  WalletVouchers,
} from "@low6dev/react-feature-wallet/dist";
import { LoggedInFooter } from "components/shared";
import { handleError } from "utils";
import { useTranslations } from "hooks";

import WalletTabs from "./WalletTabs";

const StyledWrapper = styled.div`
  padding-top: 1rem;
`;

const StyledTicketsWrapper = styled.div`
  margin: 0 1rem;
`;

const Wallet = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [translations] = useTranslations();

  return (
    <StyledWrapper>
      {/* <WalletTabs activeTab={activeTab} setActiveTab={setActiveTab} /> */}
      {activeTab === 0 && (
        <div>
          <AppWallet
            hideDeposit
            dateLocale="en-gb"
            translations={translations}
            onError={handleError}
            currencySymbol={process.env.REACT_APP_CURRENCY_SYMBOL}
          />
        </div>
      )}
      {activeTab === 1 && (
        <StyledTicketsWrapper>
          <WalletTickets
            prizeImageUrl="https://cdn.jsdelivr.net/gh/low6dev/fiver-react-assets@main/m4soldout.png"
            onError={handleError}
          />
        </StyledTicketsWrapper>
      )}
      {activeTab === 2 && process.env.REACT_APP_IS_UBER === "true" && (
        <WalletVouchers />
      )}

      <LoggedInFooter />
    </StyledWrapper>
  );
};

export default Wallet;
