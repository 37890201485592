import React from "react";
import { useSelector } from "react-redux";
import { Alert, FloatingHeader } from "fiver-react-ui-components";

const ScoreFloatingHeaderVerify = () => {
  const { isScrolledDown } = useSelector((state) => state.app);
  return (
    <FloatingHeader isScrolledDown={isScrolledDown} justifyContent="center">
      <Alert isDanger padding="0.5rem 0.75rem" margin="0.75rem">
        Warning: You are still taking a break from playing
      </Alert>
    </FloatingHeader>
  );
};

export default ScoreFloatingHeaderVerify;
