import { isMobileSafari, mobileModel, isChrome } from "react-device-detect";
import { rgba } from "polished";

const hasNotch = !isMobileSafari && !isChrome && mobileModel === "iPhone";

const colorPrimary = process.env.REACT_APP_COLOR_PRIMARY || "red";

export const themeDefault = {
  colorBackground: process.env.REACT_APP_COLOR_BACKGROUND || "#e2e6ea",
  colorContentBackgroundMobile: "#ffffff",
  colorContentBackground: "#ffffff",
  colorTicketBackground: "#fafafa",
  colorTicketText: rgba("white", 0.96),
  colorTicketLabel: rgba("white", 0.67),
  colorPrimary: "transparent",
  colorPrimaryText: "white",
  colorAccent: "#222222",
  colorAccentText: "white",
  colorDark: "#040404",
  colorDarkText: "white",
  colorPlaceholder: process.env.REACT_APP_COLOR_PLACEHOLDER || "#e1e1e1",
  colorText: process.env.REACT_APP_COLOR_TEXT || "#222222",
  colorBorder: process.env.REACT_APP_COLOR_BORDER || "#e7e7e7",
  colorTopbarBackground:
    process.env.REACT_APP_COLOR_TOPBAR_BACKGROUND || colorPrimary,
  colorLightGrey: "#444545",
  colorBurgerTitleBackground: "#040404",
  colorBurgerTitleText: "white",
  colorSuccess: "#12a608",
  colorSuccessText: "white",
  colorDanger: "#666",
  colorLabel: process.env.REACT_APP_COLOR_LABEL || "#818a95",
  colorLabelHover: "#909aa8",
  colorDisabledBackground: "#1d1e1e",
  colorDisabledText: "#404040",
  colorSkeleton: "#666666",
  colorSkeletonShimmer: "#888888",
  colorInputBackground: process.env.REACT_APP_INPUT_BACKGROUND || "#222222",
  colorPrize: "#f3c744",
  colorPrizeText: "#570003",
  colorSpinnerBackground: "rgba(0, 0, 0, 0.1)",
  colorSpinnerBackgroundForDark: "rgba(255, 255, 255, 0.175)",
  colorAlertSuccessText: "#ffffff",
  colorAlertSuccessBackground: "#029851",
  colorAlertDefaultText: "#ffffff",
  colorAlertDefaultBackground: "#029851",
  colorAlertDangerText: "#ffffff",
  colorAlertDangerBackground: "#C10606",
  colorCardBackground: "white",
  colorNavbarBackground: "#F8F8F9",
  colorNavbarLink: "#999999",
  colorNavbarLinkActive: "#D10A10",
  screenWidthXxs: "320px",
  screenWidthXs: "400px",
  screenWidthSm: "768px",
  screenWidthMd: "992px",
  screenWidthLg: "1200px",
  screenWidthXl: "1920px",
  screenWidthXxl: "2400px",
  railWidth: "500px",
  borderRadius: "14px",
  topbarHeight: 64,
  topbarPadding: hasNotch ? 32 : 0,
  navbarHeight: "80px",
  inputFontStyle: "normal",
  inputBorderStyle: "normal",
  inputBorderRadius: "0",
  inputFontSize: "1rem",
  inputFontWeight: "600",
  buttonFontSize: "1rem",
  boxShadowCard: "0px 0px 10px rgba(0,0,0,0.08)",
  zIndexNavbar: 5,
  zIndexPlaceBet: 6,
};
