import { combineReducers } from "redux";

import scoresReducer from "components/feature/scores/reducer";
import leaderboardReducer from "components/feature/leaderboard/reducer";
import drawerReducer from "components/layout/drawer/reducer";
import translationsReducer from "components/feature/translations/reducer";
import authReducer from "components/feature/auth/reducer";
import appReducer from "components/feature/app/reducer";

export default combineReducers({
  scores: scoresReducer,
  leaderboard: leaderboardReducer,
  drawer: drawerReducer,
  translations: translationsReducer,
  auth: authReducer,
  app: appReducer,
});
