import { useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import history from "router/history";
import { useSelector, useDispatch } from "react-redux";
import { Router, Switch } from "react-router-dom";
import { cssTransition, ToastContainer } from "react-toastify";

import { MatIcon } from "fiver-react-ui-components";
import { Drawer as AppDrawer } from "@low6dev/low6-private/dist";

import { handleError } from "utils";
import { useTranslations, useCreateTheme } from "hooks";

import { clearDrawer } from "components/layout/drawer/actions";
import { loadFromLocalStorage } from "components/feature/auth/actions";

import AppRoute from "./AppRoute";
import GlobalStyle from "./GlobalStyle";

import Blocked from "components/Blocked";
import NotFound from "components/feature/auth/404";
import Login from "components/feature/auth/Login";
import Forgot from "components/feature/auth/ForgotPassword";
import Signup from "components/feature/auth/Signup";
import ConfirmMobile from "components/feature/auth/ConfirmMobile";
import ChangePin from "components/feature/auth/ChangePin";
import ChangePassword from "components/feature/auth/ChangePassword";
import Profile from "components/feature/profile/Profile";
import Scores from "components/feature/scores/Scores";
import Leaderboard from "components/feature/leaderboard/Leaderboard";
import Wallet from "components/feature/wallet/Wallet";
import Landing from "components/feature/landing/Landing";
import SelfExclusion from "components/feature/auth/SelfExclusion";
import TakeBreak from "components/feature/auth/TakeBreak";
import { setScrolledDown, loadAppStuff } from "./feature/app/actions";

const StyledCloseButton = styled(MatIcon)`
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
`;

const slideTransition = cssTransition({
  enter: "slideUp",
  exit: "slideDown",
  duration: 300,
  collapseDuration: 300,
});

const Root = () => {
  const dispatch = useDispatch();

  const { isOpen, useAssetsCdn, pageSlug, pageTitle } = useSelector(
    (s) => s.drawer
  );

  const { isScrolledDown } = useSelector((state) => state.app);

  const { language, brand } = useSelector((s) => s.auth);

  const [theme] = useCreateTheme(brand);

  if (theme) {
    document.getElementById("preloader").classList.remove("theme-loading");
  }

  useTranslations(language, true);

  useEffect(() => {
    const listener = (e) => {
      const { scrollY } = window;

      if (document.getElementsByClassName("tippy-popper").length > 0) {
        const body = document.getElementsByTagName("body")[0];
        body.removeChild(document.getElementsByClassName("tippy-popper")[0]);
      }

      if (!isScrolledDown && scrollY > 1) {
        dispatch(setScrolledDown(true));
      }

      if (isScrolledDown && scrollY < 1) {
        dispatch(setScrolledDown(false));
      }
    };

    window.addEventListener("scroll", listener);

    dispatch(loadFromLocalStorage());

    dispatch(loadAppStuff());

    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, [isScrolledDown]);

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <ToastContainer
            closeButton={
              <StyledCloseButton size="30px">close</StyledCloseButton>
            }
            // transition={slideTransition}
            autoClose={false}
            limit={1}
            position="bottom-center"
            hideProgressBar
          />
          <AppDrawer
            isOpen={isOpen}
            useAssetsCdn={useAssetsCdn}
            pageSlug={pageSlug}
            pageTitle={pageTitle}
            onDrawerClosed={() => dispatch(clearDrawer())}
            onError={handleError}
          />
          <Switch>
            <AppRoute
              path="/"
              component={Landing}
              isLandingPage
              exact
              hasPrimaryBackground={true}
              hideTopbar={true}
              hideNav
            />
            <AppRoute path="/login" component={Login} exact hideNav />
            <AppRoute path="/forgot" component={Forgot} exact hideNav />
            <AppRoute path="/change-pin" component={ChangePin} exact hideNav />
            <AppRoute
              path="/change-password"
              component={ChangePassword}
              exact
              hideNav
            />
            <AppRoute
              path="/self-exclusion"
              component={SelfExclusion}
              exact
              hideNav
            />
            <AppRoute path="/take-break" component={TakeBreak} exact hideNav />
            <AppRoute
              path="/confirm-phone"
              component={ConfirmMobile}
              exact
              hideNav
            />
            <AppRoute
              path="/signup"
              component={Signup}
              exact
              hideNav
              noTopPadding
            />
            <AppRoute
              path="/profile"
              component={Profile}
              exact
              topbarBurgerMenu
            />
            <AppRoute
              path="/scores"
              component={Scores}
              exact
              topbarBurgerMenu
            />
            <AppRoute
              path="/leaderboard"
              component={Leaderboard}
              exact
              topbarBurgerMenu
            />
            <AppRoute
              path="/wallet"
              component={Wallet}
              exact
              topbarBurgerMenu
            />
            <AppRoute component={NotFound} hideNav topbarBurgerMenu />
          </Switch>
        </>
      </ThemeProvider>
    </Router>
  );
};

export default Root;
