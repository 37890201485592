import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { withTheme } from "styled-components";
import { createImageCdnLink } from "utils";
import { useTranslations } from "hooks";
import { Card } from "fiver-react-ui-components";

import ScoresPicksRow from "./ScoresPicksRow";
import ScoresTipster from "./ScoresTipster";
import { updatePicks } from "./actions";

import { AffiliateBanner } from "@low6dev/react-shared-banners/dist";

const StyledWrapper = styled.div`
  padding: 0;
`;

const StyledBanner = styled.img`
  width: 100%;
  display: block;
  margin: 0 0 1rem;
`;

const StyledCard = styled(Card)`
  padding: 0;
  margin: 1.5rem 1rem;
`;

const ScoresPicks = ({ isLocked, theme, showTipster }) => {
  const dispatch = useDispatch();
  const { picks, matches, activePickem, activeBet } = useSelector(
    (state) => state.scores
  );
  const { showAffiliateBanners } = useSelector((s) => s.app);

  const tipsterMock = [
    { questionId: 4035, homeScore: 1, awayScore: 2 },
    { questionId: 4036, homeScore: 1, awayScore: 3 },
    { questionId: 4037, homeScore: 2, awayScore: 4 },
    { questionId: 4038, homeScore: 2, awayScore: 3 },
    // {questionId: 4039, homeScore: 2, awayScore: 6},
    // {questionId: 4040, homeScore: 2, awayScore: 3},
    // {questionId: 4040, homeScore: 2, awayScore: 9},
  ];

  const isCorrect = (match, index) => {
    const homeScorePrediction = picks?.selections[index]?.homeScore;
    const awayScorePrediction = picks?.selections[index]?.awayScore;

    if (match.status === "IN_PLAY" || match.status === "COMPLETED") {
      if (
        match.homeScore === homeScorePrediction &&
        match.awayScore === awayScorePrediction
      ) {
        return true;
      } else {
        return false;
      }
    }

    return undefined;
  };

  const getCardColor = (match, index) => {
    let color = "transparent";
    if (isCorrect(match, index) && activeBet) {
      color = theme.colorSuccess;
    } else if (isCorrect(match, index) === false && activeBet) {
      color = theme.colorDanger;
    }
    return color;
  };

  const getCardBorder = (match, index) => {
    return `5px solid ${getCardColor(match, index)}`;
  };

  const skeletonRows = new Array(4).fill(0);

  let indexesToShowBanner = [2];

  if (process.env.REACT_APP_AFFILIATE_BANNER_INDEXES) {
    indexesToShowBanner = process.env.REACT_APP_AFFILIATE_BANNER_INDEXES.split(
      ","
    ).map((i) => +i);
  }

  return !activePickem ? (
    <StyledWrapper>
      {skeletonRows.map((r, i) => (
        <StyledCard key={i} padding="1rem 0">
          <ScoresPicksRow isLoading={!activePickem} />
        </StyledCard>
      ))}
    </StyledWrapper>
  ) : (
    <StyledWrapper>
      {showTipster && (
        <ScoresTipster
          style={{ margin: "0 1rem 1.375rem" }}
          onActive={() => {
            dispatch(
              updatePicks({
                ...picks,
                selections: [...JSON.parse(JSON.stringify(tipsterMock))],
              })
            );
          }}
          onDisactive={() => {
            picks.selections.forEach((item) => {
              item.homeScore = "";
              item.awayScore = "";
              item.homeScore = "";
              item.awayScore = "";
              delete item.homeEdited;
              delete item.awayEdited;
            });

            dispatch(updatePicks(picks));
          }}
        />
      )}
      {matches.map((m, index) => (
        <>
          {indexesToShowBanner.includes(index) && showAffiliateBanners && (
            <AffiliateBanner hasBottomMargin />
          )}

          {indexesToShowBanner.includes(index) &&
          (process.env.REACT_APP_AFFILIATE_BANNER ||
            process.env.REACT_APP_AFFILIATE_BANNER_IFRAME) ? (
            <AffiliateBanner
              hasBottomMargin={false}
              iframeUrl={process.env.REACT_APP_AFFILIATE_BANNER_IFRAME}
              imageUrl={process.env.REACT_APP_AFFILIATE_BANNER}
              ctaUrl={process.env.REACT_APP_AFFILIATE_URL}
              index={index}
              appName={process.env.REACT_APP_TITLE}
            />
          ) : null}
          <StyledCard key={index} border={getCardBorder(m, index)}>
            <ScoresPicksRow
              cardColor={getCardColor(m, index)}
              isCorrect={isCorrect(m, index)}
              status={m?.status}
              isLoading={!activePickem}
              isLocked={isLocked}
              index={index}
              kickoff={m?.kickoff}
              homeTeam={m?.homeTeam}
              awayTeam={m?.awayTeam}
              liveHomeScore={m?.homeScore}
              liveAwayScore={m?.awayScore}
              minute={m?.matchTime}
              period={m?.period}
              homeScore={picks?.selections[index]?.homeScore}
              awayScore={picks?.selections[index]?.awayScore}
              homeEdited={picks?.selections[index]?.homeEdited}
              awayEdited={picks?.selections[index]?.awayEdited}
              tipsterMock={tipsterMock}
            />
          </StyledCard>
          {matches?.length > 0 &&
            index === 1 &&
            activePickem?.status === "APPROVED" &&
            process.env.REACT_APP_MIDDLE_BANNER && (
              <StyledBanner
                src={createImageCdnLink(process.env.REACT_APP_MIDDLE_BANNER)}
              />
            )}
          {matches?.length > 0 &&
            index === 3 &&
            activePickem?.status === "APPROVED" &&
            process.env.REACT_APP_MIDDLE_BANNER_2 && (
              <StyledBanner
                src={createImageCdnLink(process.env.REACT_APP_MIDDLE_BANNER_2)}
              />
            )}
        </>
      ))}
    </StyledWrapper>
  );
};

export default withTheme(ScoresPicks);
