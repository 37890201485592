import { createGlobalStyle } from "styled-components";

const baseFontSize = process.env.REACT_APP_FONT_SIZE || "1rem";

export default createGlobalStyle`

  html {
    font-size: ${baseFontSize};
  }

  body {
    font-family: ${(p) => p.theme.fontFamily};
    color: ${(props) => props.theme.colorText};
    background: ${(p) => p.theme.colorContentBackground};
  }

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    html {
      display: flex;
      flex-direction: column;
      min-height: 100%;
    }

    body {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      background: ${(p) => p.theme.colorBackground}
    }

    #root {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }
  }

  button, input, select {
    color: ${(props) => props.theme.colorText};
    font-family: ${(p) => p.theme.fontFamily};
  }

  a, button, input, select {
    font-weight: ${(p) => p.theme.fontWeightSemibold}
  }

  body .Toastify__toast-container {
    padding: 0;
    width: 100%;

    @media (min-width: ${(props) => props.theme.screenWidthMd}) {
      width: ${(props) => props.theme.railWidth};
    }
  }

  body .Toastify__toast {
    padding: 0;
    margin-bottom: 0;
    user-select: none;
    display: flex;
    justify-content: center;
  }

  body .Toastify__toast-container--bottom-center {
    bottom: 0;
  }

  body .Toastify__toast--success {
    background: ${(props) => props.theme.colorPrimary};
  }

  body .Toastify__toast--error {
    background: ${(props) => props.theme.colorDanger};
  }

  body .Toastify__close-button {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }

  body .Toastify__toast-body {
    padding: 2.5rem 2rem;
    text-align:center;
    font-size: 1.25rem;
    font-family: ${(p) => p.theme.fontFamilyDefault};
  }

  body .tippy-popper {
    z-index: 5 !important;
  }

  body .tippy-tooltip {
    background: white !important;
    color: ${(p) => p.theme.colorText};
    font-size: 1rem;

    font-family: ${(p) => p.theme.fontFamilyDefault};
    line-height: 1.75;
    padding: 1rem;
    border-radius: ${(p) => p.theme.borderRadius};
    box-shadow: 0px 0px 20px rgba(0,0,0,0.125);
  }

  body .tippy-tooltip [x-circle] {
    background: inherit;
  }

  body .tippy-tooltip .tippy-tooltip-content {
    font-weight: ${(p) => p.theme.fontWeightNormal};
  }

  body .tippy-tooltip .tippy-tooltip-content div {
    line-height: 1.5;
    font-size: inherit;
    font-weight: inherit;
  }

  body .tippy-popper[x-placement^=top] [x-arrow] {
    border-top-color: white;
  }

  body .tippy-popper[x-placement^=bottom] [x-arrow] {
    border-bottom-color: white;
  }
`;
