import { useEffect } from "react";
import history from "router/history";

const useCheckLogin = () => {
  useEffect(() => {
    if (localStorage.getItem("fiver_react_access_token")) {
      history.push("/scores");
    }
  }, []);

  return [];
};

export default useCheckLogin;
