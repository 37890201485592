import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { BlockButton } from "fiver-react-ui-components";
import { openDrawer } from "components/layout/drawer/actions";

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0;
  border-bottom: 2px solid ${(props) => props.theme.colorBorder};

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

const StyledNumber = styled.div`
  flex: 1 0 30px;
  width: 30px;
  height: 30px;
  max-width: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${(p) => p.theme.fontWeightBold};
  border-radius: 50%;
  color: white;
  background: ${(p) => p.theme.colorPrimary};
`;

const StyledText = styled.div`
  flex: 1 1 auto;
  margin-left: 0.75rem;
  line-height: 1.35;
  font-size: 0.875rem;
  font-weight: ${(p) => p.theme.fontWeightNormal};
  font-family: ${(p) => p.theme.fontFamilyDefault};
`;

const ScoresHelpList = (props) => {
  const dispatch = useDispatch();
  const tr = useSelector((s) => s.translations);

  const { language } = useSelector((s) => s.auth);
  const { activePickem } = useSelector((s) => s.scores);

  const handlePageButtonClicked = () => {
    const pageUrl =
      language === "en" ? "game-rules-english2" : "game-rules-dutch2";
    dispatch(openDrawer(true, pageUrl, tr.rules));
  };

  const createStepOneText = (value) =>
    value?.replace("$1", activePickem?.questions?.length);

  return (
    <>
      <div style={{ marginBottom: "1rem" }}>
        <StyledRow>
          <StyledNumber>1</StyledNumber>
          <StyledText>{createStepOneText(tr.howToPlayStep1)}</StyledText>
        </StyledRow>
        <StyledRow>
          <StyledNumber>2</StyledNumber>
          <StyledText>{tr.howToPlayStep2}</StyledText>
        </StyledRow>
        <StyledRow>
          <StyledNumber>3</StyledNumber>
          <StyledText>{tr.howToPlayStep3}</StyledText>
        </StyledRow>
        {tr.howToPlayStep4 !== "" && (
          <StyledRow>
            <StyledNumber>4</StyledNumber>
            <StyledText>{tr.howToPlayStep4}</StyledText>
          </StyledRow>
        )}
        {tr.howToPlayStep5 !== "" && (
          <StyledRow>
            <StyledNumber>5</StyledNumber>
            <StyledText>{tr.howToPlayStep5}</StyledText>
          </StyledRow>
        )}
      </div>
      <BlockButton
        style={{ borderRadius: "50px", marginBottom: "1rem" }}
        onClick={handlePageButtonClicked}
      >
        {tr.rules}
      </BlockButton>
    </>
  );
};

export default ScoresHelpList;
