import moment from "moment";

export const getPickemDateInfo = (pickem) => {
  let matchDates = [];
  let matchMonths = [];

  const questions = !pickem?.id
    ? []
    : pickem?.questions.map((pq) => pq.data.kickoff);

  for (let q of questions) {
    const date = new moment(q);

    if (!matchDates.some((s) => s === date.format("D"))) {
      matchDates.push(date.format("D"));
    }

    if (!matchMonths.some((s) => s === date.format("MMM"))) {
      matchMonths.push(date.format("MMM"));
    }
  }

  const matchesSpanMultipleMonths = matchMonths.length > 1;
  const matchesSpanMultipleDays = matchDates.length > 1;

  if (matchDates.length === 0) {
    matchDates = undefined;
  }

  return {
    matchesSpanMultipleMonths,
    matchesSpanMultipleDays,
    matchDates,
    matchMonths,
  };
};
