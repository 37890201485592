import React from "react";
import styled from "styled-components";
import { MatIcon, Overlay } from "fiver-react-ui-components";
import { createImageCdnLink } from "utils";

const StyledModalWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 7;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: ${(p) => (p.isVisible ? "visible" : "hidden")};
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  transition-property: visibility, opacity;
  transition-duration: 0.25s;
  width: 90%;

  @media (min-width: ${(p) => p.theme.screenWidthXs}) {
    width: 400px;
  }
`;

const StyledModal = styled.a`
  border-radius: 8px;
  background: white;
  transform: translateY(${(p) => (p.isVisible ? "0" : "1.5rem")});
  transition: transform 0.25s;
`;

const StyledImage = styled.img`
  display: block;
  width: 100%;
  border-radius: 8px 8px 0 0;
`;

const StyledWrapper = styled.div`
  position: relative;
  color: ${(p) => p.theme.colorPrimary};
  padding: 1.5rem;
`;

const StyledGritingText = styled.div`
  font-size: 1.75rem;
  font-weight: ${(p) => p.theme.fontWeightBold};
  text-align: center;
  margin-bottom: 0.75rem;
`;

const StyledContent = styled.span`
  position: relative;
  text-align: center;
`;

const StyledTagline = styled.div`
  color: ${(p) => p.theme.colorPrimary};
  line-height: 1.2;
  font-size: 1.175rem;
  font-weight: ${(p) => p.theme.fontWeightBold};
  width: 70%;
  margin: 0 auto 1rem;
  text-align: center;
  & span {
    color: ${(p) => p.theme.colorSuccess};
  }
`;

const StyledExpired = styled.div`
  font-size: 0.875rem;
  width: 100%;
  margin: 0 auto 1rem;
`;

const StyledClose = styled.button`
  position: absolute;
  padding: 0;
  right: 0;
  top: 0;
  transform: translateY(-44px);
  color: white;
  cursor: pointer;

  & > span {
    font-size: 2.75rem;
  }
`;

const StyledMatIcon = styled(MatIcon)`
  font-size: 1.75rem;
`;

const StyledRedeem = styled.div`
  padding: 1.25rem 0;
  background: ${(p) => p.theme.colorSuccess};
  cursor: pointer;
  text-align: center;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
`;

const Weekly7PopupBanner = ({
  isVisible,
  onOverlayClicked,
  discount,
  expired,
  textGreeting,
  uberUrl,
}) => {
  const getDiscountText = (discount) => {
    return (
      <>
        You’ve received <span>£{discount} off</span> your next Uber Eats order.
      </>
    );
  };

  const handleCloseClicked = (e) => {
    e.stopPropagation();
    onOverlayClicked();
  };

  return (
    <>
      <Overlay zIndex={6} isVisible={isVisible} onClick={onOverlayClicked} />
      <StyledModalWrapper isVisible={isVisible}>
        <StyledClose onClick={(e) => handleCloseClicked(e)}>
          <StyledMatIcon style={{ fontSize: "1.75rem" }}>close</StyledMatIcon>
        </StyledClose>
        <StyledModal href={uberUrl} target="_blank" isVisible={isVisible}>
          <StyledImage src={createImageCdnLink("UberBanner.jpg")} />

          <StyledWrapper>
            <StyledGritingText>{textGreeting}</StyledGritingText>

            <StyledContent>
              <StyledTagline>{getDiscountText(discount)}</StyledTagline>
              <StyledExpired>{expired}</StyledExpired>
            </StyledContent>
            <StyledRedeem>Redeem Now</StyledRedeem>
          </StyledWrapper>
        </StyledModal>
      </StyledModalWrapper>
    </>
  );
};

export default Weekly7PopupBanner;
