export const SET_AUTH = "auth:SET_AUTH";

export function setAuth(data) {
  return {
    type: SET_AUTH,
    payload: data,
  };
}

export function loadFromLocalStorage() {
  const accessToken = localStorage.getItem("fiver_react_access_token");

  return {
    type: SET_AUTH,
    payload: { accessToken },
  };
}
