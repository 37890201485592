import React from "react";
import styled from "styled-components";
import { Blinker, ClubName } from "components/shared";
import { DateTime } from "fiver-react-ui-components";
import { useTranslations } from "hooks";
import moment from "moment";

const StyledWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: space-between;
  background: ${(p) =>
    p.isCorrect
      ? p.theme.colorSuccess
      : p.isCorrect === false
      ? p.theme.colorDanger
      : "transparent"};
  color: ${(p) => (p.isInPlay || p.isCompleted ? "white" : p.theme.colorLabel)};
`;

const StyledKickoff = styled.div`
  padding: 0.325rem 0;
  text-align: center;
  font-size: 1.125rem;
  color: ${(p) => p.theme.colorLabel};
  margin-bottom: 0.5rem;
`;

const StyledMatchInfo = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledEnd = styled.div`
  flex: 0 1 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1px;
`;

const StyledMiddle = styled.div`
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 168px;
  min-width: 1px;
`;

const StyledMiddleScore = styled.div`
  flex: 1 1 auto;
  text-align: center;
  border: 5px solid transparent;
  font-size: 2rem;
  font-weight: bold;
`;

const StyledClubName = styled(ClubName)`
  color: white;
  font-size: 0.75rem;
  text-align: center;
  font-weight: bold;
`;

const StyledMiddleTime = styled.div`
  flex: 0 0 40px;
  width: 40px;
  text-align: center;

  & svg {
    transform: translateY(-6px) scale(0.5);
  }

  & svg rect {
    fill: white;
  }
`;

const StyledKickoffDate = styled.div`
  font-size: 0.75rem;
  text-align: center;
  margin-bottom: 0.25rem;
  text-transform: capitalize;
`;

const ScoresPicksRowInfoBar = ({
  isInPlay,
  isCorrect,
  isCompleted,
  liveHomeScore,
  liveAwayScore,
  kickoff,
  minute,
  period,
  homeTeam,
  awayTeam,
}) => {
  const [translations] = useTranslations();

  let dateWithTimezone = minute;

  if (process.env.REACT_APP_TIMEZONE_OFFSET) {
    dateWithTimezone = new moment(kickoff).add(
      +process.env.REACT_APP_TIMEZONE_OFFSET,
      "hours"
    );
  }

  const isContestClosed = process.env.REACT_APP_IS_CONTEST_CLOSED === "true";

  return (
    <>
      {/* {isContestClosed || (!isInPlay && !isCompleted) ? (
        <StyledKickoff>
          <StyledKickoffDate>
            <DateTime format="D MMMM">{kickoff}</DateTime>
          </StyledKickoffDate>
          <DateTime format="HH:mm">{dateWithTimezone}</DateTime>
        </StyledKickoff>
      ) : null} */}
      {(isInPlay || isCompleted) && (minute === 0 || minute > 0) ? (
        <StyledWrapper
          isInPlay={isInPlay}
          isCorrect={isCorrect}
          isCompleted={isCompleted}
        >
          <StyledMatchInfo>
            <StyledEnd>
              <StyledClubName>{homeTeam}</StyledClubName>
            </StyledEnd>
            <StyledMiddle>
              <StyledMiddleScore>{liveHomeScore}</StyledMiddleScore>
              <StyledMiddleTime>
                {isCompleted || period === "FullTime" ? (
                  "FT"
                ) : period === "HalfTime" ? (
                  "HT"
                ) : minute || minute === 0 ? (
                  <>
                    {`${minute}`}
                    <Blinker />
                  </>
                ) : (
                  <>{translations.live}</>
                )}
              </StyledMiddleTime>
              <StyledMiddleScore>{liveAwayScore}</StyledMiddleScore>
            </StyledMiddle>
            <StyledEnd>
              <StyledClubName>{awayTeam}</StyledClubName>
            </StyledEnd>
          </StyledMatchInfo>
        </StyledWrapper>
      ) : null}
    </>
  );
};

export default ScoresPicksRowInfoBar;
