import React from "react";
import styled from "styled-components";
import { MatIcon } from "fiver-react-ui-components";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  font-family: ${(p) => p.theme.fontFamilyDefault};
  ${(props) =>
    props.hasBorderTop && `border-top: 2px solid ${props.theme.colorBorder}`};

  padding: 6rem 2rem;
`;

const StyledTitle = styled.div`
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
`;

const StyledSubtitle = styled.div`
  font-size: 0.875rem;
  font-weight: ${(p) => p.theme.fontWeightSemibold};
  color: ${(props) => props.theme.colorLabel};
`;

const StyledIcon = styled(MatIcon)`
  margin-bottom: 1rem;
  font-size: 3rem;
  color: ${(props) => props.theme.colorLabel};
`;

const EmptyScreen = ({
  hasBorderTop,
  emptyIcon,
  emptyTitle,
  emptySubtitle,
}) => (
  <StyledWrapper hasBorderTop={hasBorderTop}>
    <StyledIcon>{emptyIcon || "cancel_presentation"}</StyledIcon>
    <StyledTitle>{emptyTitle || "No Active Match"}</StyledTitle>
    <StyledSubtitle>{emptySubtitle || "Next match soon"}</StyledSubtitle>
  </StyledWrapper>
);

export default EmptyScreen;
