import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/string/repeat";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import axios from "axios";

import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-tippy/dist/tippy.css";
import "rc-slider/assets/index.css";
import "./assets/css/toast-transitions.css";

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.headers.common["x-app-id"] = process.env.REACT_APP_TENANT;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "fiver_react_access_token"
)}`;

require("dotenv").config();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
