import React from "react";
import styled, { keyframes } from "styled-components";

const blink = keyframes`
  0% { opacity: 0; }
  50% { opacity: 0; }
  51% { opacity: 1 }
  100% { opacity: 1; }
`;

const StyledBlinker = styled.span`
  margin-left: 0.075rem;
  animation: ${blink} 1.75s infinite;
  height: 4px;

  & svg {
    height: 100%;
    transform: scale(0.5) translateY(-8px);
  }

  & rect {
    fill: ${(p) => p.theme.colorText};
  }
`;

const Blinker = () => (
  <StyledBlinker>
    <svg
      width="3"
      height="8"
      viewBox="0 0 3 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="3" height="8" fill="#484848" />
    </svg>
  </StyledBlinker>
);

export default Blinker;
