export const SET_APP = "app:SET_APP";

export function setScrolledDown(isScrolledDown) {
  return {
    type: SET_APP,
    payload: {
      isScrolledDown,
    },
  };
}

export const setApp = (app) => {
  return {
    type: SET_APP,
    payload: app,
  };
};

export const loadAppStuff = () => async (dispatch) => {
  const appStuff = {
    showAffiliateBanners:
      process.env.REACT_APP_SHOW_AFFILIATE_BANNERS === "true"
  };

  dispatch(setApp(appStuff));
};