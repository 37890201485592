import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  padding: 0 0 2rem;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.div`
  font-size: 120px;
  text-shadow: 8px 11px #d6dde7;
  margin-bottom: 1rem;
`;

const StyledSubtitle = styled.div`
  color: ${(props) => props.theme.colorLabel};
`;

const NotFound = ({}) => (
  <StyledWrapper>
    <StyledTitle>404</StyledTitle>
    <StyledSubtitle>Page not found</StyledSubtitle>
  </StyledWrapper>
);

export default NotFound;
