import { useSelector } from "react-redux";
import styled from "styled-components";
import { rgba } from "polished";
import { Tabs } from "fiver-react-ui-components";

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 4;
  top: ${(p) => p.theme.topbarHeight}px;
  left: 0;
  width: 100%;
  background: ${(p) => p.theme.colorCardBackground};
  box-shadow: 0px 0px 10px ${rgba("black", 0.05)};
  transition: transform 0.325s;

  @media (min-width: ${(p) => p.theme.screenWidthMd}) {
    left: 50%;
    width: ${(p) => p.theme.railWidth};
    transform: translateX(-50%);
  }
`;

const Wrapper = ({ setActiveTab, activeTab }) => {
  return (
    <StyledWrapper>
      <Tabs
        tabs={
          process.env.REACT_APP_IS_UBER === "true"
            ? ["Wallet", "Tickets", "Vouchers"]
            : ["Wallet", "Tickets"]
        }
        onTabClicked={setActiveTab}
        activeTab={activeTab}
      />
    </StyledWrapper>
  );
};

export default Wrapper;
