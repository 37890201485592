import React from "react";
import styled from "styled-components";
import { MatIcon, Skeleton, DateTime } from "fiver-react-ui-components";

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 5;
  top: ${(p) => p.theme.topbarHeight + p.theme.topbarPadding}px;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  border-bottom: 2px solid ${(p) => p.theme.colorBorder};
  background: white;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    left: 50%;
    width: ${(props) => props.theme.railWidth};
    margin: 0 auto;
    transform: translateX(-50%);
  }
`;

const StyledEnd = styled.div`
  flex: 0 0 3rem;
  display: flex;
  justify-content: center;
  color: ${(p) => p.theme.colorLabel};
`;

const StyledMiddle = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;

  & > time {
    text-transform: capitalize;
    font-size: 1.0625rem;
    font-family: ${(p) => p.theme.fontFamily};
    font-weight: ${(p) => p.theme.fontWeightBold};
  }
`;

const StyledNavButton = styled.button`
  width: 3rem;
  height: 2.5rem;
  padding: 0;

  & > span {
    font-size: 1.75rem;
  }

  &:disabled {
    color: #d9dce0;
    cursor: not-allowed;
  }
`;

const ScoresTopbar = ({
  activeIndex,
  highestIndex,
  pickemId,
  kickoff,
  onBackClicked,
  onNextClicked,
  language,
  isLoading,
}) => {
  return (
    <StyledWrapper>
      {onBackClicked && (
        <StyledEnd>
          <StyledNavButton
            disabled={activeIndex === 0 || isLoading}
            onClick={() => onBackClicked(pickemId)}
          >
            <MatIcon>keyboard_arrow_left</MatIcon>
          </StyledNavButton>
        </StyledEnd>
      )}

      <StyledMiddle>
        {!kickoff ? (
          <Skeleton skeletonHeight="18px" skeletonWidth="120px" />
        ) : (
          <DateTime format="D MMMM YYYY">{kickoff}</DateTime>
        )}
      </StyledMiddle>
      {onNextClicked && (
        <StyledEnd>
          <StyledNavButton
            onClick={() => onNextClicked()}
            disabled={activeIndex === highestIndex || isLoading}
          >
            <MatIcon>keyboard_arrow_right</MatIcon>
          </StyledNavButton>
        </StyledEnd>
      )}
    </StyledWrapper>
  );
};

export default ScoresTopbar;
