import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "router/history";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import {
  MatIcon,
  Spinner,
  TextButton,
  BlockButton,
} from "fiver-react-ui-components";
import { useTranslations } from "hooks";

import ScoresBetNowConfirm from "./ScoresBetNowConfirm";

const StyledWrapper = styled.div`
  position: fixed;
  overflow: hidden;
  z-index: ${(p) => p.theme.zIndexPlaceBet};
  bottom: 0;
  left: 0;
  display: flex
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 17rem;
  padding: 1rem 0 2rem;
  background: ${(p) => p.theme.colorPrimary};
  transform: translateY(
    ${(p) => (p.isOpen ? "50%" : "100%")}
  );
  transition: transform 0.3s;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    left: 50%;
    width: ${(props) => props.theme.railWidth};
    margin: 0 auto;
    transform: translate(-50%, ${(p) => (p.isOpen ? "50%" : "100%")});
  }
`;

const StyledWrapperContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(${(p) => (p.isSubmitted ? "-50%" : 0)});
  transition: transform 0.35s;
`;

const StyledButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 7.5rem;
`;

const StyledButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
  height: 64px;
  border-radius: 32px;
  letter-spacing: 1px;
  background: ${(p) => p.theme.colorCardBackground};
  color: ${(p) => p.theme.colorText};
  font-size: 1.375rem;
  outline: none;
  transition: box-shadow 0.25s;
`;

const StyledButtonText = styled.span``;

const StyledSpinnerWrapper = styled.div`
  height: 6.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScoresBetNow = ({ isOpen, isLocked }) => {
  const dispatch = useDispatch();

  const [isSubmitted, setSubmitted] = useState(false);

  const [translations] = useTranslations();

  const { activePickem, picks, isSaving, hasSavedPicks, matches } = useSelector(
    (state) => state.scores
  );

  const buttonIndex = matches.length * 2 + 1;

  const handleBetNowClicked = () => {
    setSubmitted(true);
  };

  const handleCancelClicked = (e) => {
    e.stopPropagation();
    setSubmitted(false);
  };

  return !isLocked && isOpen !== undefined ? (
    <StyledWrapper isOpen={isOpen && !hasSavedPicks}>
      {isSaving ? (
        <StyledSpinnerWrapper>
          <Spinner forDark />
        </StyledSpinnerWrapper>
      ) : !hasSavedPicks ? (
        <StyledWrapperContent isSubmitted={isSubmitted}>
          <StyledButtonWrapper onClick={handleBetNowClicked}>
            <StyledButton tabIndex={buttonIndex}>
              <StyledButtonText>{translations.placeBet}</StyledButtonText>
            </StyledButton>
          </StyledButtonWrapper>
          <ScoresBetNowConfirm handleCancelClicked={handleCancelClicked} />
        </StyledWrapperContent>
      ) : null}
    </StyledWrapper>
  ) : null;
};

export default ScoresBetNow;
