import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { rgba } from "polished";
import { Skeleton } from "fiver-react-ui-components";
import LeaderboardRowHeader from "./shared/LeaderboardRowHeader";
import { getMyBet } from "./actions";

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 3;
  bottom: ${(p) => p.theme.navbarHeight};
  left: 0;
  width: 100%;
  background: white;
  padding: 0;
  ${"" /* border-top: 2px solid ${(p) => p.theme.colorBorder}; */};
  box-shadow: 0px -4px 15px ${rgba("black", 0.08)};

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    width: ${(props) => props.theme.railWidth};
    left: 50%;
    transform: translateX(-50%);
  }
`;

const StyledProfileRow = styled.div`
  align-self: stretch;
  margin: 0;
  padding: 0;
`;

const Wrapper = ({ setActivePickemId }) => {
  const dispatch = useDispatch();

  const {
    activeBet,
    isLeaderboardEmpty,
    allPickems,
    activePickem,
    profile,
    isShowingMyBet,
    hadBet,
  } = useSelector((s) => s.leaderboard);

  let row;

  let isLoadingBet = !activeBet?.id;

  if (activeBet?.id) {
    row = {
      rank: activeBet?.rank,
      points: activeBet?.points,
      user: {
        username: profile?.username,
        image: profile?.image,
      },
    };
  } else {
    isLoadingBet = false;
  }

  const isInPlayOrCompleted =
    activePickem?.status === "COMPLETED" || activePickem?.status === "IN_PLAY";

  const isLoading = !activePickem?.id;

  const handleRowClicked = () => {
    window.scrollTo(0, 0);

    if (isInPlayOrCompleted && !isShowingMyBet) {
      dispatch(getMyBet(activeBet?.id));
      setActivePickemId(activeBet?.id);
    }
  };

  return hadBet !== false && isInPlayOrCompleted ? (
    <StyledWrapper onClick={handleRowClicked}>
      <StyledProfileRow>
        {isLoading || isLoadingBet || !row?.user ? (
          <LeaderboardRowHeader isSkeleton isPageHeader />
        ) : hadBet ? (
          <LeaderboardRowHeader
            setActivePickemId={setActivePickemId}
            isFooter
            row={row}
            index={0}
          />
        ) : null}
      </StyledProfileRow>
    </StyledWrapper>
  ) : null;
};

export default Wrapper;
