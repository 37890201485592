import React from "react";
import { useSelector } from "react-redux";
import { createImageCdnLink } from "utils";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import { MatIcon, DateTime, Spinner } from "fiver-react-ui-components";
import { Blinker, ClubName } from "components/shared";
import moment from "moment";
import { getPickemDateInfo } from "../utils";

const StyledWrapper = styled.div`
  position: relative;
`;

const openStyles = css`
  opacity: 1;
  visibility: visible;
`;

const StyledSpinnerWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${rgba("white", 0.75)};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 0.25s;

  ${(p) => p.isOpen && openStyles};
`;

const StyledSpinner = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  ${"" /* background: ${(p) => p.theme.colorPrimary}; */}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledRow = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 5px solid ${(p) => p.theme.colorCardBackground};
  padding: 0.375rem;
`;

const StyledRowEnd = styled.div`
  flex: 1 1 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.isLeft ? "flex-start" : "flex-end")};
  padding: 0 1rem 0 0;
`;

const StyledRowMiddle = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledScore = styled.div`
  flex: 0 0 auto;
`;

const StyledScorePrediction = styled.div`
  font-size: 2.25rem;
  text-align: center;
  margin-bottom: 0.325rem;
  display: flex;
  align-items: center;
`;

const StyledScoreActual = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: center;
  ${(p) => p.theme.colorText};
  margin-right: 0.5rem;
  font-size: 1.25rem;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    font-size: 1.5rem;
  }
`;

const StyledMatchDate = styled.div`
  font-size: 0.65rem;
  color: ${(p) => p.theme.colorLabel};
  margin-bottom: 0.2rem;
`;

const StyledMatchTime = styled.div``;

const StyledClubWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 4rem;
  max-width: 4rem;
  padding: 0 0.25rem;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    padding: 0 0.5rem;
    width: 4.5rem;
    max-width: 4.5rem;
  }
`;

const StyledClubTitle = styled(ClubName)`
  flex: 1 1 auto;
  line-height: 1.75;
  text-transform: ${(p) => p.theme.specialTextTransform};
  text-align: center;
  font-size: 0.635rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledClubKit = styled.img`
  height: 30px;
  display: block;
  margin-bottom: 0.125rem;
`;

const StyledCorrectIcon = styled(MatIcon)`
  font-size: 1.5rem;
  color: ${(p) => p.theme.colorSuccess};
`;

const StyledWrongIcon = styled(MatIcon)`
  font-size: 1.5rem;
  color: ${(p) => p.theme.colorDanger};
`;

const StyledFutureIcon = styled(MatIcon)`
  font-size: 1.5rem;
  color: ${(p) => p.theme.colorDisabledText};
`;

const StyledTwoTwoButton = styled.div`
  position: absolute;
  top: 15px;
  left: -70px;
  padding: 0.325rem 0.5rem;
  border-radius: 3px;
  background: ${(p) => (p.isPrize ? p.theme.colorPrize : p.theme.colorPrimary)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 200px;
  transform: rotate(-45deg);
`;

const StyledPath = styled.path`
  stroke-width: 2px;
  stroke: ${(p) => (p.isPrize ? p.theme.colorPrizeText : "white")};
  fill: ${(p) => (p.isPrize ? p.theme.colorPrizeText : "white")};
`;

const StyledScoreInput = styled.div`
  background: ${(p) => p.theme.colorLightGrey};
  border-radius: 4px;
  text-align: center;
  font-size: 1.5rem;
  width: 1.875rem;
  height: 3rem;
  line-height: 3rem;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    width: 2.25rem;
  }
`;

const StyledDivider = styled.div`
  margin: 0 0.125rem 0.14rem;
  font-size: 24px;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    margin: 0 0.5rem 0.4rem;
    font-size: inherit;
  }
`;

const StyledBlinkerWrapper = styled.div`
  transform: translateY(-6px);
`;

const Wrapper = ({ row }) => {
  const { activeUserBet, activeBet, activePickem } = useSelector(
    (state) => state.leaderboard
  );

  const pickemDateInfo = getPickemDateInfo(activePickem);

  const { matchesSpanMultipleDays } = pickemDateInfo;

  const homeScoreActual = (index) =>
    activePickem.questions[index]?.data?.homeScore;
  // const homeScorePrediction = 0;

  const awayScoreActual = (index) =>
    activePickem.questions[index]?.data?.awayScore;

  const isContestClosed = process.env.REACT_APP_IS_CONTEST_CLOSED === "true";

  const isFuture = (fixtureStatus) => fixtureStatus === "UPCOMING";

  const isWildcard = (index, match) => {
    return activeUserBet?.twoTwoBetFootballMatchId === match.data.id;
  };

  const isRight = (index, obj) => {
    return (
      !isFuture(obj.status) &&
      +homeScoreActual(index) === +getScorePrediction(obj, index, true) &&
      +awayScoreActual(index) === +getScorePrediction(obj, index, false)
    );
  };

  const isWilcardRight = (index, obj) => {
    return +homeScoreActual(index) === 2 && +awayScoreActual(index) === 2;
  };

  const getScorePrediction = (question, index, isHomeTeam) => {
    if (activeUserBet?.selections) {
      const { selections } = activeUserBet;

      const option = question?.options?.find(
        (obj) => obj.id === selections[index]
      );

      return isHomeTeam
        ? option?.label.substring(0, 1)
        : option?.label.substring(2, 3);
    } else {
      return "-";
    }
  };

  const dateWithTimezone = (date) => {
    return new moment(date).add(
      +process.env.REACT_APP_TIMEZONE_OFFSET,
      "hours"
    );
  };

  const isInPlayAndLive = (status, period) =>
    status === "IN_PLAY" && (period === "FirstHalf" || period === "SecondHalf");

  return (
    <StyledWrapper>
      <StyledSpinnerWrapper isOpen={!activeUserBet}>
        <StyledSpinner>
          <Spinner />
        </StyledSpinner>
      </StyledSpinnerWrapper>

      {activePickem?.questions.map((obj, index) => (
        <StyledRow>
          <StyledRowMiddle>
            <StyledClubWrapper>
              <StyledClubKit
                src={
                  obj?.data?.homeTeam?.logo ||
                  createImageCdnLink("kit-feyenoord.png")
                }
              />
              <StyledClubTitle>{obj?.data?.homeTeam?.name}</StyledClubTitle>
            </StyledClubWrapper>

            <StyledScore>
              <StyledScorePrediction>
                <StyledScoreInput>
                  {getScorePrediction(obj, index, true)}
                </StyledScoreInput>{" "}
                <StyledDivider>:</StyledDivider>
                <StyledScoreInput>
                  {getScorePrediction(obj, index, false)}
                </StyledScoreInput>
              </StyledScorePrediction>
            </StyledScore>
            <StyledClubWrapper>
              <StyledClubKit
                src={
                  obj?.data?.awayTeam?.logo ||
                  createImageCdnLink("kit-feyenoord.png")
                }
              />
              <StyledClubTitle>{obj?.data?.awayTeam?.name}</StyledClubTitle>
            </StyledClubWrapper>
          </StyledRowMiddle>
          <StyledRowEnd>
            <StyledScoreActual>
              {isInPlayAndLive(obj?.status, obj?.data?.period) ? (
                <>
                  {`${homeScoreActual(index)}:${awayScoreActual(index)} ${
                    obj?.data.matchTime
                  }`}
                  <StyledBlinkerWrapper>
                    <Blinker />
                  </StyledBlinkerWrapper>
                </>
              ) : obj?.status === "COMPLETED" ||
                obj?.data?.period === "FullTime" ? (
                `${homeScoreActual(index)}:${awayScoreActual(index)} FT`
              ) : obj?.data?.period === "HalfTime" ? (
                `${homeScoreActual(index)}:${awayScoreActual(index)} HT`
              ) : (
                <div>
                  {matchesSpanMultipleDays &&
                    !isInPlayAndLive(obj?.status, obj?.data?.period) && (
                      <StyledMatchDate>
                        <DateTime format="D MMMM YYYY">
                          {dateWithTimezone(obj?.data?.kickoff)}
                        </DateTime>
                      </StyledMatchDate>
                    )}
                  <StyledMatchTime>
                    <DateTime format="HH:mm">
                      {dateWithTimezone(obj?.data?.kickoff)}
                    </DateTime>
                  </StyledMatchTime>
                </div>
              )}
            </StyledScoreActual>
            {/* {isFuture(obj.status) && (
              <StyledFutureIcon>panorama_fish_eye</StyledFutureIcon>
            )} */}
            {!isFuture(obj.status) && isRight(index, obj) && (
              <StyledCorrectIcon>check_circle</StyledCorrectIcon>
            )}
            {!isFuture(obj.status) && !isRight(index, obj) && (
              <StyledWrongIcon>cancel</StyledWrongIcon>
            )}
          </StyledRowEnd>
        </StyledRow>
      ))}
    </StyledWrapper>
  );
};

export default Wrapper;
