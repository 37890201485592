import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { withTheme } from "styled-components";
import { getUsersBet } from "./actions";
import { useTranslations } from "hooks";
import { Card, Skeleton } from "fiver-react-ui-components";
import LeaderboardListRow from "./LeaderboardListRow";
import LeaderboardRowHeader from "./shared/LeaderboardRowHeader";
import axios from "axios";
import Confetti from "react-confetti";

const StyledWrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledConfetti = styled.div`
  padding: 1.5rem 0 0.5rem;
  position: relative;
`;

const StyledImage = styled.img`
  display: block;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 0.75rem;
  position: relative;
  z-index: 3;
`;

const StyledName = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  text-align: center;
  position: relative;
  z-index: 3;
`;

const StyledPrize = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const StyledAmount = styled.div`
  color: ${(p) => p.theme.colorSuccess};
  font-size: 1.875rem;
  margin-left: 0.5rem;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`;

const LeaderboardList = ({ activePickemId, setActivePickemId, theme }) => {
  const dispatch = useDispatch();

  const [translations] = useTranslations();

  const [profile, setProfile] = useState({});

  useEffect(() => {
    (async () => {
      const res = await axios.get(`/me`);
      setProfile(res?.data?.data);
    })();
  }, []);

  const {
    canBet,
    activePickem,
    activeBet,
    skeletonRows,
    table,
    isShowingMyBet,
  } = useSelector((s) => s.leaderboard);

  const isContestClosed = process.env.REACT_APP_IS_CONTEST_CLOSED === "true";

  const handleRowClicked = (id) => {
    if (
      (activePickem?.status === "COMPLETED" ||
        activePickem?.status === "IN_PLAY") &&
      (!isContestClosed || activePickem?.status === "COMPLETED") &&
      !isShowingMyBet
    ) {
      if (activePickemId === id) {
        setActivePickemId(undefined);
      } else {
        dispatch(getUsersBet(id));
        setActivePickemId(id);
      }
    }
  };

  return (
    <>
      {table ? (
        <>
          {activeBet?.prize ? (
            <Card>
              <StyledConfetti>
                <StyledImage src={profile.image} />
                <StyledName>{profile.firstName}</StyledName>
                <Wrapper>
                  <Confetti
                    width="600"
                    height="600"
                    colors={[theme?.colorSuccess]}
                    friction="0.95"
                    numberOfPieces="800"
                    drawShape={(ctx) => {
                      ctx.rect(0, 0, 4, 10);
                      ctx.fill();
                    }}
                  />
                </Wrapper>
              </StyledConfetti>
              <StyledPrize>
                WINS <StyledAmount> £{activeBet.prize}</StyledAmount>
              </StyledPrize>
            </Card>
          ) : null}

          {table?.map((row) => (
            <LeaderboardListRow
              active={activePickemId}
              row={row}
              onRowClicked={() => handleRowClicked(row?.betId)}
            />
          ))}
        </>
      ) : (
        <>
          {skeletonRows.map((s, index) => (
            <LeaderboardRowHeader index={index} isSkeleton />
          ))}
        </>
      )}
    </>
  );
};

export default withTheme(LeaderboardList);
