import React, {useState, useEffect} from "react";
import styled, { withTheme } from "styled-components";
import { createImageCdnLink } from "utils";
import {MatIcon} from "fiver-react-ui-components";

const StyledWrapper = styled.div`
  margin: 0 0 1.375rem;
  background: ${(p) => p.isActive ? p.theme.colorSuccess : p.theme.colorLightGrey};
  display: flex;
`;

const StyledLeft = styled.div`
  flex: 0 0 15%;
  max-width: 80px;
`;

const StyledRight = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;
  padding: 0.75rem;
  align-items: center;
  min-width: 0;
`;

const StyledImageWrapper = styled.div`
  padding-top: 100%;
  position: relative;
`;

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: fill;
  width: 100%;
  height: 100%;
  display: block;
`;

const NameWrapper = styled.div`
  flex: 1 1 auto;
  min-width: 0;
`;

const StyledTitle = styled.div`
  font-size: 0.625rem;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  color: ${p => p.isActive ? p.theme.colorPrimary : p.theme.colorDisabledText};
`;

const StyledName = styled.div`
  font-size: 0.875rem;
  color: ${(p) => p.isActive ? '#fff' : p.theme.colorPrimary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledControl = styled.div`
  margin-left: 0.5rem;
  display: flex;
  color: ${(p) => p.isActive ? '#fff' : p.theme.colorPrimary};
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 160px;
`;

const StyledCheckBox = styled.div`
  border: 3px solid ${p => p.isActive ? p.theme.colorPrimary : p.theme.colorDisabledText};
  width: 1.375rem;
  height: 1.375rem;
  background: ${p => p.isActive ? p.theme.colorPrimary : 'transparent'};
  margin-left: 0.75rem;
  color: ${(p) => p.isActive ? '#fff' : ''};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ScoresTipster = ({
  onActive,
  onDisactive,
  style,
}) => {
  const [isActive, setIsActive] = useState(false)
  const [firstRender, setFirstRender] = useState(true)

  const setTipster = () => {
    setIsActive(prev => !prev);
  }

  useEffect(() => {
    if (isActive) {
      setFirstRender(false)
      onActive();
    } else if (!firstRender) {
      onDisactive();
    }
  }, [isActive, firstRender])

  return (
    <StyledWrapper isActive={isActive} style={style}>
      <StyledLeft>
        <StyledImageWrapper>
          <StyledImage src={createImageCdnLink('weeklyTipster.jpg')}/>
        </StyledImageWrapper>
      </StyledLeft>
      <StyledRight>
        <NameWrapper>
          <StyledTitle isActive={isActive}>
            TIPSTER
          </StyledTitle>
          <StyledName isActive={isActive}>
            Mark Lawrenson
          </StyledName>
        </NameWrapper>
        <StyledControl isActive={isActive}>
          View predictions
          <StyledCheckBox
            isActive={isActive}
            onClick={setTipster}
          >
            {isActive ? <MatIcon>check</MatIcon> : null}
          </StyledCheckBox>
        </StyledControl>
      </StyledRight>
    </StyledWrapper>
  );
};

export default withTheme(ScoresTipster);
