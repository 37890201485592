import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { createImageCdnLink } from "utils";

const StyledOuter = styled.div`
  background-color: #142328;
  padding: 0 0 1rem;
  margin: 0 0 1rem;
`;

const StyledWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  height: 120px;
  background-color: #142328;
  background-image: url(${createImageCdnLink(
    process.env.REACT_APP_TOP_BANNER
  )});
  background-position: 50% 0%;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;

  @media (min-width: ${(props) => props.theme.screenWidthSm}) {
    height: 120px;
  }
`;

const StyledText = styled.div`
  width: 52%;
  padding-left: 1rem;
  color: white;
  font-size: 1.5rem;
  line-height: 1.05;
  -webkit-line-clamp: 2;

  @media (min-width: ${(props) => props.theme.screenWidthSm}) {
    font-size: 1.75rem;
    padding-left: 1.5rem;
    width: 60%;
  }
`;

const StyledUberLogo = styled.img`
  margin-left: 1rem;
  width: 80px;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    margin-left: 2rem;
  }
`;

const StyledSmallPrint = styled.div`
  color: ${rgba("white", 0.8)};
  font-size: 0.6rem;
  line-height: 1.5;

  max-width: 500px;
  margin: 0 auto;
  padding: 0 1rem;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    padding: 0 2rem;
  }
`;

const Wrapper = () => {
  const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL;
  const voucherPrizeAmount = process.env.REACT_APP_VOUCHER_PRIZE_AMOUNT;

  return (
    <StyledOuter>
      <StyledWrapper>
        <StyledUberLogo
          src={createImageCdnLink(process.env.REACT_APP_CTA_ICON)}
        />
        <StyledText>
          Score a Free {currencySymbol}
          {voucherPrizeAmount} voucher every week.
        </StyledText>
      </StyledWrapper>
      <StyledSmallPrint>
        18+ UK Only. Entry closes earlier of{" "}
        {process.env.REACT_APP_CONTEST_CLOSE_TIME} BST{" "}
        {process.env.REACT_APP_CONTEST_CLOSE_DATE} or{" "}
        {process.env.REACT_APP_CONTEST_MAX_ENTRIES} entries. Jackpot split if >1
        winner. Full terms apply. Further terms apply to Uber Eats Voucher -
        expiry 23:59 on {process.env.REACT_APP_CONTEST_CLOSE_DATE}. Contest
        managed by Low6.
      </StyledSmallPrint>
    </StyledOuter>
  );
};

export default Wrapper;
