import React from "react";
import { LegalFooter as AppLegalFooter } from "fiver-react-ui-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslations } from "hooks";
import { openDrawer } from "components/layout/drawer/actions";

const LegalFooter = ({ isDark }) => {
  const dispatch = useDispatch();
  const [translations] = useTranslations();

  const handlePageButtonClicked = (pageSlug, pageTitle) => {
    dispatch(openDrawer(pageSlug, pageTitle));
  };

  return (
    <AppLegalFooter
      isDark={isDark}
      hasSmallerFontSize
      getPage={handlePageButtonClicked}
      appTitle={process.env.REACT_APP_TITLE}
      hideGamblingReferences={
        process.env.REACT_APP_HIDE_GAMBLING_REFERENCES === "true"
      }
      supportEmail={process.env.REACT_APP_SUPPORT_EMAIL}
      termsConditionsFilename="terms"
      translations={translations}
    />
  );
};

export default LegalFooter;
