import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import history from "router/history";
import { Profile as AppProfile } from "@low6dev/react-feature-profile/dist";
import { handleError } from "utils";
import { openDrawer } from "components/layout/drawer/actions";
import { Card, FormRowLabel } from "fiver-react-ui-components";
import { LoggedInFooter, LanguagePicker } from "components/shared";
import { getTranslations } from "components/feature/translations/actions";
import { setAuth } from "components/feature/auth/actions";
import { useTranslations } from "hooks";
import { createImageCdnLink } from "utils";
import { clearScores } from "components/feature/scores/actions";
import ProfileSupport from "./ProfileSupport";
import { isAndroid } from "react-device-detect";

const StyledWrapper = styled.div`
  padding-top: 1rem;
`;

const Profile = () => {
  const [translations] = useTranslations();
  const dispatch = useDispatch();

  const {
    isScrolledDown,
    isNonUkUser,
    isDevEnvironment,
    tenantDetails,
  } = useSelector((state) => state.app);

  const { language } = useSelector((s) => s.auth);

  const gotoRoute = (path) => history.push(path);

  const handleLogoutSuccess = () => {
    history.push("/");
    dispatch(setAuth({ accessToken: undefined }));
    dispatch(clearScores());
    localStorage.removeItem("fiver_react_access_token");
  };

  const handleProfileSaveSuccess = () => {
    toast.success(`${translations.profile} ${translations.saved}`);
  };

  const handleLanguageChanged = (language) => {
    dispatch(setAuth({ language }));
    dispatch(getTranslations(language));
  };

  const handlePageButtonClicked = (pageSlug, pageTitle) => {
    dispatch(openDrawer(pageSlug, pageTitle));
  };

  const uberAppUrl = !isAndroid
    ? "https://apps.apple.com/us/app/uber-eats-food-delivery/id1058959277"
    : "https://play.google.com/store/apps/details?id=com.ubercab.eats";

  const ctaUrl =
    process.env.REACT_APP_IS_UBER === "true"
      ? uberAppUrl
      : process.env.REACT_APP_CTA_URL;

  return (
    <StyledWrapper>
      <AppProfile
        hideChangePin
        hideLogoutButton={false}
        hideEmailField={false}
        hideVerifyButton={false}
        hideOpenWalletButton
        hideCheckAllCheckbox={process.env.REACT_APP_IS_BLENHEIM === "true"}
        isFiver
        isMinimalAddress={false}
        isNonUkUser={isNonUkUser}
        isScrolledDown={isScrolledDown}
        onLogoutSuccess={handleLogoutSuccess}
        onProfileSaveSuccess={handleProfileSaveSuccess}
        onVerifyMobileClicked={() => gotoRoute("/confirm-phone")}
        onChangePinClicked={() => gotoRoute("/change-pin")}
        onChangePasswordClicked={() => gotoRoute("/change-password")}
        onError={handleError}
        ctaUrl={ctaUrl}
        ctaIconUrl={
          process.env.REACT_APP_CTA_ICON
            ? createImageCdnLink(process.env.REACT_APP_CTA_ICON)
            : undefined
        }
        ctaText={process.env.REACT_APP_CTA_TEXT}
        tenantDetails={tenantDetails}
        privacySlug="privacy"
        onPageButtonClicked={handlePageButtonClicked}
      />
      {false && (
        <Card>
          <FormRowLabel>{translations.language}</FormRowLabel>
          <LanguagePicker value={language} onChange={handleLanguageChanged} />
        </Card>
      )}
      <LoggedInFooter />
    </StyledWrapper>
  );
};

export default Profile;
