import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslations } from "hooks";
import { MatIcon } from "fiver-react-ui-components";
import styled, { css } from "styled-components";
import { getAllPickems, getUsersBet } from "./actions";

const height = "80px";

const openStyles = css`
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
`;

const openStylesDesktop = css`
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
`;

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 4;
  left: 0;
  bottom: ${(p) => p.theme.navbarHeight};
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transform: translate(0, ${height});
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3rem;
  border-top: 2px solid ${(p) => p.theme.colorBorder};
  background: ${(p) => p.theme.colorPrimary};
  transition-property: opacity, visibility, transform;
  transition-duration: 0.325s;

  ${(p) => p.isOpen && openStyles};

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    left: 50%;
    width: ${(props) => props.theme.railWidth};
    margin: 0 auto;
    transform: translate(-50%, ${height});

    ${(p) => p.isOpen && openStylesDesktop};
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  height: 56px;
  padding: 0 2.5rem;
  background: white;
  color: ${(p) => p.theme.colorPrimary};
  text-transform: ${(p) => p.theme.specialTextTransform};
  font-size: 1.065rem;
  margin-left: 1rem;
`;

const StyledButtonText = styled.span`
  margin-right: 0.75rem;
`;

const StyledTitle = styled.div`
  font-size: 0.875rem;
  font-family: ${(p) => p.theme.fontFamilyDefault};
  color: white;
  margin-bottom: 0.25rem;
`;

const StyledFixture = styled.div`
  color: white;
  font-size: 1.065rem;
  font-family: ${(p) => p.theme.fontFamilyDefault};
`;

const LeaderboardRefresh = ({ activePickemId, setActivePickemId }) => {
  const dispatch = useDispatch();

  const [translations] = useTranslations();

  const { hasUpdates, updatedQuestion } = useSelector((s) => s.leaderboard);

  const handleRefresh = () => {
    setActivePickemId(undefined);
    dispatch(getAllPickems(true));
    dispatch(getUsersBet(activePickemId));
  };

  return (
    <StyledWrapper isOpen={hasUpdates}>
      <div>
        <StyledTitle>{translations.goalIn}</StyledTitle>
        <StyledFixture>
          {updatedQuestion || translations.leaderboard}
        </StyledFixture>
      </div>
      <StyledButton onClick={handleRefresh}>
        <StyledButtonText>{translations.update}</StyledButtonText>
        <MatIcon>refresh</MatIcon>
      </StyledButton>
    </StyledWrapper>
  );
};

export default LeaderboardRefresh;
