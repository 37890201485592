import React from "react";
import {
  Low6Footer as AppLow6Footer,
  Card,
  Low6Button,
} from "fiver-react-ui-components";
import { createImageCdnLink, handleError } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { useTranslations } from "hooks";
import { openDrawer } from "components/layout/drawer/actions";
import { isAndroid } from "react-device-detect";

const Low6Footer = () => {
  const dispatch = useDispatch();

  const [translations] = useTranslations();

  const { brand, language } = useSelector((s) => s.auth);

  const handlePageButtonClicked = () => {
    const pageUrl = process.env.REACT_APP_RULES_FILE_NAME;
    dispatch(openDrawer(pageUrl, translations.rules));
  };

  const appLogo = createImageCdnLink(
    process.env.REACT_APP_APP_LOGO || process.env.REACT_APP_MAIN_LOGO
  );

  const uberAppUrl = !isAndroid
    ? "https://apps.apple.com/us/app/uber-eats-food-delivery/id1058959277"
    : "https://play.google.com/store/apps/details?id=com.ubercab.eats";

  const ctaUrl =
    process.env.REACT_APP_IS_UBER === "true"
      ? uberAppUrl
      : process.env.REACT_APP_CTA_URL;

  return (
    <AppLow6Footer
      onError={handleError}
      getPage={handlePageButtonClicked}
      appVersion="1.0.0"
      appTitle={process.env.REACT_APP_TITLE}
      appIconUrl={appLogo}
      translations={translations}
      hideGamblingReferences={
        process.env.REACT_APP_HIDE_GAMBLING_REFERENCES === "true"
      }
      hideCtaButton={false}
      ctaUrl={ctaUrl}
      ctaIconUrl={
        process.env.REACT_APP_CTA_ICON &&
        createImageCdnLink(process.env.REACT_APP_CTA_ICON)
      }
      ctaText={process.env.REACT_APP_CTA_TEXT}
    />
  );
};

export default Low6Footer;
