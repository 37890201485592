import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { MatIcon } from "fiver-react-ui-components";
import { isAndroid } from "react-device-detect";

const StyledButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 1.2rem 1rem;
`;

const StyledIcon = styled(MatIcon)`
  font-size: 2rem;
  color: ${(p) => p.theme.colorText};
`;

const Wrapper = () => {
  const { activePickem } = useSelector((s) => s.scores);

  const showShare = activePickem?.status === "APPROVED" && !isAndroid;

  const handleClick = (e) => {
    e.preventDefault();
    if (navigator.share) {
      navigator
        .share({
          title: `${
            process.env.REACT_APP_VOUCHER_SHARE_TITLE
              ? process.env.REACT_APP_VOUCHER_SHARE_TITLE
              : "Weekly 7"
          }`,
          text: `Hi, earn yourself a £5 Uber Eats discount code by placing your free picks! Play the game now at:`,
          url: process.env.REACT_APP_VOUCHER_SHARE_URL
            ? process.env.REACT_APP_VOUCHER_SHARE_URL
            : document.location.href,
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch((error) => {
          console.error("Something went wrong sharing the blog", error);
        });
    }
  };

  return true ? (
    <StyledButton onClick={handleClick}>
      <StyledIcon>ios_share</StyledIcon>
    </StyledButton>
  ) : null;
};

export default Wrapper;
