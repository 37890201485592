import React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import history from "router/history";
import { Login as AppLogin } from "@low6dev/low6-private/dist";
import { handleError, createImageCdnLink } from "utils";
import { LegalFooter } from "components/shared";
import { setAuth } from "./actions";
import { useCheckLogin } from "hooks";

const StyledWrapper = styled.div`
  padding-top: 1rem;
`;

const Login = () => {
  const dispatch = useDispatch();
  const translations = useSelector((s) => s.translations);

  useCheckLogin();

  const handleLoginSuccess = ({ accessToken }) => {
    dispatch(setAuth({ accessToken }));
    localStorage.setItem("fiver_react_access_token", accessToken);

    window.xtremepush(
      "event",
      "LandingPage.Login",
      JSON.stringify({
        AppName: process.env.REACT_APP_TITLE,
      })
    );

    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    history.push("/scores");
  };

  const mainLogo = createImageCdnLink(
    process.env.REACT_APP_LOGIN_LOGO || process.env.REACT_APP_MAIN_LOGO
  );

  return (
    <StyledWrapper>
      <AppLogin
        translations={translations}
        onLoginSuccess={handleLoginSuccess}
        onError={handleError}
        appIconUrl={mainLogo}
      />
      <LegalFooter />
    </StyledWrapper>
  );
};

export default Login;
