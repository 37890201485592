import {
  SET_SCORES,
  SET_SCORES_BETS,
  UPDATE_PICKS,
  SET_LOCKED,
  SET_SAVING_PICKS,
  SET_HAS_SAVED_PICKS,
  SET_POPUP_VISIBLE,
  SET_SCROLLED_DOWN,
} from "./actions";

const initialState = {
  matches: [],
  picks: {
    selections: [],
  },
  isSaving: false,
  isLocked: false,
  hasSavedPicks: false,
  activePickem: undefined,
  activeBet: undefined,
  isContestEmpty: undefined,
  isPlacingBet: undefined,
  isPopupVisible: false,
  isScrolledDown: false,
  uber: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_SCROLLED_DOWN:
      return {
        ...state,
        isScrolledDown: payload,
      };
    case SET_SCORES:
    case SET_SCORES_BETS:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_PICKS:
      return {
        ...state,
        picks: payload,
      };
    case SET_LOCKED:
      return {
        ...state,
        isLocked: payload,
      };
    case SET_SAVING_PICKS:
      return {
        ...state,
        isSaving: payload,
      };
    case SET_HAS_SAVED_PICKS:
      return {
        ...state,
        hasSavedPicks: payload,
      };
    case SET_POPUP_VISIBLE:
      return {
        ...state,
        isPopupVisible: payload,
      };
    default:
      return state;
  }
}
