import { useEffect, useState } from "react";
import { themeDefault } from "theme";

const useCreateTheme = () => {
  const [theme, setTheme] = useState(themeDefault);

  const loadTheme = () => {
    let colorPrimary, colorSuccess, colorAccent, colorNavbarLinkActive;

    colorPrimary = process.env.REACT_APP_COLOR_PRIMARY;
    colorSuccess =
      process.env.REACT_APP_COLOR_SUCCESS || themeDefault.colorSuccess;
    colorAccent =
      process.env.REACT_APP_COLOR_ACCENT || themeDefault.colorAccent;
    colorNavbarLinkActive =
      process.env.REACT_APP_COLOR_NAVBAR_LINK_ACTIVE ||
      themeDefault.colorNavbarLinkActive;

    const fontFamily = `${process.env.REACT_APP_FONT_FAMILY}, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif`;

    const fontFamilyDefault = `${
      process.env.REACT_APP_FONT_FAMILY_DEFAULT ||
      process.env.REACT_APP_FONT_FAMILY
    }, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif`;

    setTheme({
      ...themeDefault,
      fontFamily,
      fontFamilyDefault,
      colorPrimary,
      colorAccent,
      colorSuccess,
      colorNavbarLinkActive,
      colorSpecial: process.env.REACT_APP_COLOR_SPECIAL || colorSuccess,
      colorPrize: process.env.REACT_APP_COLOR_PRIZE || colorSuccess,
      colorPrizeText: process.env.REACT_APP_COLOR_PRIZE_TEXT || "white",

      colorContentBackgroundMobile:
        process.env.REACT_APP_COLOR_CONTENT_BACKGROUND_MOBILE || "transparent",
      colorContentBackground:
        process.env.REACT_APP_COLOR_CONTENT_BACKGROUND || "#f5f5f5",
      colorCardBackground:
        process.env.REACT_APP_COLOR_CARD_BACKGROUND || "#ffffff",
      colorTicketBackground:
        process.env.REACT_APP_COLOR_TICKET_BACKGROUND || "white",
      colorTicketText:
        process.env.REACT_APP_COLOR_TICKET_TEXT || theme.colorText,
      colorTicketLabel:
        process.env.REACT_APP_COLOR_TICKET_LABEL || theme.colorLabel,
      colorPrimaryText:
        process.env.REACT_APP_COLOR_PRIMARY_TEXT ||
        themeDefault.colorPrimaryText,
      colorDanger:
        process.env.REACT_APP_COLOR_DANGER || themeDefault.colorDanger,
      colorNavbarBackground:
        process.env.REACT_APP_COLOR_NAVBAR_BACKGROUND ||
        themeDefault.colorNavbarBackground,
      colorNavbarLink:
        process.env.REACT_APP_COLOR_NAVBAR_LINK || themeDefault.colorNavbarLink,
      borderRadius:
        process.env.REACT_APP_BORDER_RADIUS || themeDefault.borderRadius,
      inputFontFamily:
        process.env.REACT_APP_INPUT_FONT_FAMILY ||
        process.env.REACT_APP_FONT_FAMILY,
      inputFontStyle:
        process.env.REACT_APP_INPUT_FONT_STYLE || themeDefault.inputFontStyle,
      inputFontSize:
        process.env.REACT_APP_INPUT_FONT_SIZE || themeDefault.inputFontSize,
      inputFontWeight:
        process.env.REACT_APP_INPUT_FONT_WEIGHT || themeDefault.inputFontWeight,
      inputBorderStyle:
        process.env.REACT_APP_INPUT_BORDER_STYLE ||
        themeDefault.inputBorderStyle,
      inputBorderRadius:
        process.env.REACT_APP_INPUT_BORDER_RADIUS || themeDefault.borderRadius,
      buttonUppercase: process.env.REACT_APP_BUTTON_UPPERCASE || false,
      buttonFontSize: process.env.REACT_APP_BUTTON_FONT_SIZE || "1rem",
      fontWeightNormal: process.env.REACT_APP_FONT_WEIGHT_NORMAL || 400,
      fontWeightSemibold: process.env.REACT_APP_FONT_WEIGHT_SEMIBOLD || 600,
      fontWeightBold: process.env.REACT_APP_FONT_WEIGHT_BOLD || 700,
      specialTextTransform:
        process.env.REACT_APP_SPECIAL_TEXT_TRANSFORM || "none",
    });
  };

  useEffect(() => {
    loadTheme();
  }, []);

  return [theme];
};

export default useCreateTheme;
