import {
  GET_LEADERBOARD,
  GET_ALL_PICKEMS,
  SELECT_PICKEM,
  SET_LOADING,
} from "./actions";

const initialState = {
  allPickems: undefined,
  table: undefined,
  activePickem: {},
  activeBet: {},
  activeBetSelections: undefined,
  activeUserBet: {},
  currentMatch: {},
  profile: undefined,
  hadBet: undefined,
  hasUpdates: false,
  skeletonRows: new Array(20).fill(0),
  loading: true,
  hasMore: false,
  isLeaderboardEmpty: undefined,
  isShowingMyBet: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_LEADERBOARD:
      return {
        ...state,
        ...payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case GET_ALL_PICKEMS:
      return {
        ...state,
        allPickems: payload,
      };
    case SELECT_PICKEM:
      return {
        ...state,
        activePickem: payload,
      };
    default:
      return state;
  }
}
