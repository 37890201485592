import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { rgba } from "polished";
import { handleError } from "utils";
import { Link } from "react-router-dom";
import { Avatar } from "fiver-react-ui-components";

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  height: ${(p) => p.theme.topbarHeight}px;
`;

const StyledAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid ${rgba("white", 0.75)};
`;

const Wrapper = () => {
  const [profileImage, setProfileImage] = useState();

  useEffect(() => {
    (async () => {
      try {
        if (localStorage.getItem("fiver_react_access_token")) {
          const profileRes = await axios.get("/me");
          setProfileImage(profileRes?.data?.data?.image);
        }
      } catch ({ response }) {
        handleError(response);
      }
    })();
  }, []);

  return (
    <StyledLink to="/profile">
      {profileImage && (
        <StyledAvatarWrapper>
          <Avatar size="30px" src={profileImage} />
        </StyledAvatarWrapper>
      )}
    </StyledLink>
  );
};

export default Wrapper;
