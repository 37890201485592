import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { savePicks } from "./actions";
import { useTranslations } from "hooks";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6.5rem;
  padding: 0 2rem;
`;

const StyledConfirmText = styled.span`
  flex: 0 1 auto;
  color: white;
  margin-right: 1rem;
  font-size: 1.25rem;
  font-weight: ${(p) => p.theme.fontWeightNormal};
  font-family: ${(p) => p.theme.fontFamilyDefault};
`;

const primaryButton = css`
  background: ${(p) => p.theme.colorCardBackground};
  color: ${(p) => p.theme.colorText};
  border-radius: 28px;
  height: 40px;
`;

const StyledTextButton = styled.button`
  color: white;
  min-width: 80px;
  text-transform: ${(p) => p.theme.specialTextTransform};
  font-size: 1.25rem;

  ${(p) => p.isPrimary && primaryButton};
`;

export default ({ handleCancelClicked }) => {
  const dispatch = useDispatch();

  const [translations] = useTranslations();

  const { activePickem, picks } = useSelector((s) => s.scores);

  const handleConfirmClicked = (e) => {
    e.stopPropagation();
    dispatch(savePicks(activePickem?.id, picks, activePickem?.name));
  };

  return (
    <StyledWrapper>
      <StyledConfirmText>{translations.placeBetConfirm}</StyledConfirmText>
      <StyledTextButton onClick={handleCancelClicked}>
        {translations.no}
      </StyledTextButton>
      <StyledTextButton isPrimary onClick={handleConfirmClicked}>
        {translations.yes}
      </StyledTextButton>
    </StyledWrapper>
  );
};
