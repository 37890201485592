import { handleError } from "utils";
import axios from "axios";

export const GET_LEADERBOARD = "leaderboard:GET_LEADERBOARD";
export const GET_ALL_PICKEMS = "leaderboard:GET_ALL_PICKEMS";
export const SELECT_PICKEM = "leaderboard:SELECT_PICKEM";
export const SET_LOADING = "leaderboard:SET_LOADING";
const pageSize = +process.env.REACT_APP_LEADERBOARD_PAGE_SIZE;

export function clearLeaderboard() {
  return {
    type: GET_LEADERBOARD,
    payload: {
      allPickems: [],
      table: undefined,
      activePickem: {},
      activeBet: {},
      activeUserBet: {},
      currentMatch: {},
      hadBet: undefined,
      isLeaderboardEmpty: undefined,
      isShowingMyBet: undefined,
    },
  };
}

export const getAllPickems = (clearFirst) => async (dispatch, getState) => {
  //test comment
  if (clearFirst) {
    dispatch(clearLeaderboard());
    dispatch({
      type: GET_LEADERBOARD,
      payload: { hasUpdates: false, activeUserBet: {}, isShowingMyBet: false },
    });
  }

  try {
    const completedPickems = await axios.get(
      `/pickems?filter[sport_id]=1&include=questions&include_status=COMPLETED`
    );

    const inPlayPickems = await axios.get(
      `/pickems?filter[sport_id]=1&include=questions&include_status=IN_PLAY`
    );

    let completedPickemsData = completedPickems?.data?.data;

    const inPlayPickem = inPlayPickems?.data?.data[0];

    if (
      inPlayPickem &&
      !completedPickemsData.some((obj) => obj.id === inPlayPickem.id)
    ) {
      completedPickemsData = completedPickemsData.concat(
        inPlayPickems.data.data
      );
    }

    const pickemList = completedPickemsData.map((obj, index) => {
      obj.index = index;
      return obj;
    });

    if (pickemList?.length > 0) {
      dispatch({
        type: GET_ALL_PICKEMS,
        payload: [
          ...pickemList.map((obj) => ({
            id: obj.id,
            kickoff: obj.start,
          })),
        ],
      });
    } else {
      dispatch({
        type: GET_LEADERBOARD,
        payload: {
          isLeaderboardEmpty: true,
        },
      });
      return;
    }

    const latestPickemId = pickemList[pickemList.length - 1]?.id;

    dispatch(selectPickem(latestPickemId));
  } catch (error) {
    handleError(error);
  }
};

export const selectPickem = (pickemId, stopFilteringMyBet) => async (
  dispatch
) => {
  dispatch({
    type: GET_LEADERBOARD,
    payload: {
      table: undefined,
      activePickem: {},
      profile: undefined,
      hadBet: undefined,
      isLeaderboardEmpty: undefined,
    },
  });

  if (stopFilteringMyBet) {
    dispatch({
      type: GET_LEADERBOARD,
      payload: {
        isShowingMyBet: false,
        activeUserBet: {},
      },
    });
  }

  let pickemRes;
  let profileRes;
  let activeBetRes;
  let activeBetSelectionsRes;

  try {
    pickemRes = await axios.get(`/pickems/${pickemId}`);
    profileRes = await axios.get(`/me`);
    activeBetRes = await axios.get(`/pickem-bets?pickem_id=${pickemId}`);

    if (activeBetRes?.data?.data?.id) {
      activeBetSelectionsRes = await axios.get(
        `/pickem-bets/${activeBetRes?.data?.data[0]?.id}`
      );
    }
  } catch (error) {
    handleError(error);
  }

  dispatch({
    type: SELECT_PICKEM,
    payload: pickemRes?.data?.data,
  });

  dispatch({
    type: GET_LEADERBOARD,
    payload: {
      profile: profileRes?.data?.data,
      activeBet: !stopFilteringMyBet ? activeBetRes?.data?.data : {},
      activeBetSelections: activeBetSelectionsRes?.data?.data?.selections,
    },
  });

  dispatch(getLeaderboard(pickemId, 1, stopFilteringMyBet));
};

export const pusherUpdateMatch = (pusherPickem, activePickem) => (dispatch) => {
  console.log("pusherUpdateMatch", pusherPickem);

  const question = activePickem.questions.find(
    (obj) => obj.id === pusherPickem.question.id
  );

  // check only for goal updates
  const hasGoalUpdates =
    question.data.homeScore !== pusherPickem.question.data.homeScore ||
    question.data.awayScore !== pusherPickem.question.data.awayScore;

  question.data.matchTime = pusherPickem.question.data.matchTime;
  question.data.homeScore = pusherPickem.question.data.homeScore;
  question.data.awayScore = pusherPickem.question.data.awayScore;
  question.data.status = pusherPickem.question.status;
  question.data.period = pusherPickem.question.data.period;

  const homeTeamName = question.data.homeTeam.name;
  const awayTeamName = question.data.awayTeam.name;

  if (hasGoalUpdates) {
    dispatch({
      type: GET_LEADERBOARD,
      payload: {
        activePickem,
        hasUpdates: true,
        updatedQuestion: `${homeTeamName} - ${awayTeamName}`,
      },
    });
  } else {
    dispatch({
      type: GET_LEADERBOARD,
      payload: {
        activePickem,
      },
    });
  }
};

export const getUsersBet = (betId) => (dispatch) => {
  dispatch({
    type: GET_LEADERBOARD,
    payload: {
      activeUserBet: undefined,
    },
  });

  if (betId) {
    axios
      .get(`/pickem-bets/${betId}`)
      .then(({ data }) => {
        dispatch({
          type: GET_LEADERBOARD,
          payload: { activeUserBet: data.data },
        });
      })
      .catch(({ response }) => {
        handleError(response);
      });
  }
};

export const getMyBet = (betId) => async (dispatch) => {
  dispatch({
    type: GET_LEADERBOARD,
    payload: {
      activeUserBet: undefined,
      table: undefined,
    },
  });

  if (betId) {
    try {
      const myBetRes = await axios.get(`/pickem-bets/${betId}`);
      const myBet = myBetRes?.data?.data;

      const myProfileRes = await axios.get(`/me`);
      const myProfile = myProfileRes?.data?.data;

      dispatch({
        type: GET_LEADERBOARD,
        payload: {
          activeUserBet: myBet,
          isShowingMyBet: true,
          table: [
            {
              betId,
              points: myBet?.points,
              rank: myBet?.rank,
              user: {
                username: myProfile?.username,
                image: myProfile?.image,
              },
            },
          ],
        },
      });
    } catch ({ response }) {
      handleError(response);
    }
  }
};

export const getLeaderboard = (
  pickemId,
  pageNumber,
  stopFilteringMyBet
) => async (dispatch, getState) => {
  dispatch({
    type: GET_LEADERBOARD,
    payload: {
      activeBet: {},
      activeUserBet: {},
      isLoadingMore: true,
      pageNumber: pageNumber || 1,
    },
  });

  const newPageNumber = pageNumber || 1;

  let activeBet, activeBetId, betRecord;

  // const pageSize = 200;

  if (!pickemId) {
    dispatch({
      type: GET_LEADERBOARD,
      payload: {
        isLeaderboardEmpty: true,
        table: undefined,
        hadBet: undefined,
        loading: false,
        hasMore: false,
      },
    });

    return;
  }

  try {
    const activeBetResponse = await axios.get(
      `/pickem-bets?pickem_id=${pickemId}`
    );
    activeBet = activeBetResponse?.data?.data[0];
    activeBetId = activeBet?.id;
  } catch ({ response }) {
    handleError(response);
  }

  if (!activeBet) {
    dispatch({
      type: GET_LEADERBOARD,
      payload: {
        isLeaderboardEmpty: true,
        table: [],
        hadBet: false,
        loading: false,
      },
    });
    return;
  }

  await axios
    .get(
      `/pickems/${pickemId}/leaderboard?page=${pageNumber}&per_page=${
        pageSize || 100
      }`
    )
    .then(({ data }) => {
      const isLeaderboardEmpty =
        data.data.length === 0 && data.meta.last_page === 1;

      dispatch({
        type: GET_LEADERBOARD,
        payload: isLeaderboardEmpty
          ? { isLeaderboardEmpty: true, loading: false, isLoadingMore: false }
          : {
              isLoadingMore: false,
              isLeaderboardEmpty: false,
              loading: false,
              activeBet,
              hadBet: true,
              hasMore:
                data.meta.last_page !== newPageNumber &&
                data.meta.last_page > newPageNumber,
              table:
                newPageNumber === 1
                  ? data.data
                  : getState().leaderboard.table.concat(data.data),
            },
      });
    })
    .catch(({ error }) => {
      handleError(error);
      dispatch({
        type: GET_LEADERBOARD,
        payload: {
          isLeaderboardEmpty: !pickemId,
          noBet: !activeBetId,
          loading: false,
          table: [],
        },
      });
    });
  // dispatch({
  //   type: SET_PICKEM_ID,
  //   payload: { loading: false },
  // });
};

export function setLoading(value) {
  return {
    type: SET_LOADING,
    payload: value,
  };
}
