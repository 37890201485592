import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { createImageCdnLink } from "utils";
import { MatIcon } from "fiver-react-ui-components";
import TopbarBurger from "./TopbarBurger";
import TopbarProfile from "./TopbarProfile";

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  padding: ${(p) => p.theme.topbarPadding}px 1rem 0;
  height: ${(p) => p.theme.topbarHeight + p.theme.topbarPadding}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(p) => p.theme.colorTopbarBackground};

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    left: 50%;
    width: ${(props) => props.theme.railWidth};
    transform: translateX(-50%);
  }
`;

const StyledMainLogo = styled.img`
  height: ${(p) => p.theme.topbarHeight}px;
`;

const StyledGameLogo = styled.img`
  height: ${(p) => p.theme.topbarHeight}px;
`;

const StyledBurgerWrapper = styled.div`
  flex: 0 0 64px;
`;

const StyledIconButton = styled.button`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: ${(props) =>
    props.isBurger ? props.theme.colorDarkText : props.theme.colorPrimaryText};
`;

const StyledProfileWrapper = styled.div`
  flex: 0 0 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Topbar = ({ topbarBurgerMenu }) => {
  const { accessToken } = useSelector((s) => s.auth);
  const [isOpen, setIsOpen] = useState(false);
  const mainLogo = createImageCdnLink(process.env.REACT_APP_MAIN_LOGO);

  const gameLogo = process.env.REACT_APP_GAME_LOGO
    ? createImageCdnLink(process.env.REACT_APP_GAME_LOGO)
    : undefined;

  return (
    <>
      <TopbarBurger toggleIsOpen={setIsOpen} isOpen={isOpen} />
      <StyledWrapper hasGameLogo={gameLogo}>
        <StyledBurgerWrapper>
          {topbarBurgerMenu && (
            <StyledIconButton isBurger onClick={() => setIsOpen(!isOpen)}>
              <MatIcon size="40px" style={{ zIndex: 2 }}>
                menu
              </MatIcon>
            </StyledIconButton>
          )}
        </StyledBurgerWrapper>

        <StyledMainLogo src={mainLogo} />
        {gameLogo && <StyledGameLogo src={gameLogo} />}

        {!gameLogo && (
          <StyledProfileWrapper>
            {accessToken && <TopbarProfile />}
          </StyledProfileWrapper>
        )}
      </StyledWrapper>
    </>
  );
};

export default Topbar;
