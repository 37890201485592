import React from "react";
import styled from "styled-components";
import { useTranslations } from "hooks";

import history from "router/history";
import { ChangePin as AppChangePin } from "@low6dev/low6-private/dist";
import { handleError } from "utils";
import { LegalFooter } from "components/shared";

const StyledWrapper = styled.div`
  padding: 1rem 0 0;
`;

const ChangePin = () => {
  const [translations] = useTranslations();

  const gotoProfile = () => {
    history.push("/profile");
  };

  return (
    <StyledWrapper>
      <AppChangePin
        translations={translations}
        onError={handleError}
        onSuccess={gotoProfile}
        onCancel={gotoProfile}
      />
      <LegalFooter />
    </StyledWrapper>
  );
};

export default ChangePin;
