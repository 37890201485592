import React from "react";
import styled from "styled-components";
import { MatIcon } from "fiver-react-ui-components";

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial;
`;

const StyledTitle = styled.div`
  font-size: 2rem;
  color: #626a75;
  margin-bottom: 1rem;
`;

const StyledText = styled.div`
  color: #9aa4b1;
`;

const StyledIcon = styled(MatIcon)`
  font-size: 5rem;
  margin-bottom: 1rem;
  color: #626a75;
`;

const Blocked = () => {
  return (
    <StyledWrapper>
      <StyledIcon>app_blocking</StyledIcon>
      <StyledTitle>App niet beschikbaar</StyledTitle>
      <StyledText>Bij browsen buiten het gameplatform</StyledText>
    </StyledWrapper>
  );
};

export default Blocked;
