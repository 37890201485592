import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Avatar, MatIcon, Skeleton } from "fiver-react-ui-components";
import { selectPickem } from "../actions";

const StyledWrapper = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  height: 4rem;
  background: ${(p) =>
    p.isActive
      ? p.theme.colorSuccess
      : p.isPageHeader
      ? p.theme.colorContentBackground
      : p.theme.colorContentBackground};

  ${(p) => !p.isFooter && `border-bottom: 5px solid ${p.theme.colorBorder}`};

  padding: 0.375rem;
`;

const StyledRank = styled.div`
  flex: 0 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  ${(p) => (p.isActive ? "color: #fff" : "")};
`;

const StyledUserName = styled.div`
  flex: 1 1 auto;
  min-width: 1px;
  margin-left: 1rem;
  font-family: ${(p) => p.theme.fontFamilyDefault};
  font-size: 1rem;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(p) => (p.isActive ? "color: #fff" : "")};
`;

const StyledResults = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem 0 0;
`;

const StyledResultsIcon = styled(MatIcon)`
  margin-left: 1rem;
  ${(p) =>
    p.isActive
      ? "color: #fff"
      : p.isSuccess && `color: ${p.theme.colorSuccess}`};
  ${(p) =>
    p.isActive ? "color: #fff" : p.isDanger && `color: ${p.theme.colorDanger}`};
  ${(p) =>
    p.isActive ? "color: #fff" : p.isDefault && `color: ${p.theme.colorLabel}`};
`;

const StyledResultsValue = styled.div`
  margin-left: 0.5rem;
  font-size: 1.25rem;
  ${(p) => p.isActive && `color: #fff`};
`;

const StyledStopFilteringMyBetButton = styled.button`
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  background: ${(p) => p.theme.colorDanger};
  color: white;
  padding: 0 1rem;
  border-radius: ${(p) => p.theme.borderRadius};
`;

const LeaderboardListRowHeader = ({
  row,
  onRowClicked,
  isSkeleton,
  index,
  isActive,
  isPageHeader,
  isFooter,
  setActivePickemId,
}) => {
  const dispatch = useDispatch();

  const { activePickem, isShowingMyBet } = useSelector((s) => s.leaderboard);

  const isContestClosed = process.env.REACT_APP_IS_CONTEST_CLOSED === "true";
  const isCompleted = activePickem?.status === "COMPLETED";

  const totalQuestions = activePickem?.questions?.length;

  const startedQuestions = activePickem?.questions?.filter(
    (obj) => obj.status !== "UPCOMING"
  )?.length;

  const hasScores =
    activePickem?.status === "IN_PLAY" || activePickem?.status === "COMPLETED";

  const handleStopFilteringMyBetClicked = (e) => {
    window.scrollTo(0, 0);
    console.log("handleStopFilteringMyBetClicked", e);
    e.stopPropagation();
    setActivePickemId(undefined);
    dispatch(selectPickem(activePickem?.id, true));
  };

  return isSkeleton ? (
    <StyledWrapper isPageHeader={isPageHeader} isFooter={isFooter}>
      <StyledRank>
        <Skeleton skeletonWidth="22px" skeletonHeight="24px" />
      </StyledRank>
      <Skeleton isCircle skeletonWidth="40px" skeletonHeight="40px" />
      <StyledUserName>
        <Skeleton
          display="block"
          skeletonWidth={
            isPageHeader
              ? "200px"
              : index % 3 === 0
              ? "200px"
              : index % 3 === 1
              ? "120px"
              : index % 3 === 2
              ? "70px"
              : "20px"
          }
          skeletonHeight="24px"
        />
      </StyledUserName>
    </StyledWrapper>
  ) : (
    <StyledWrapper
      isPageHeader={isPageHeader}
      isFooter={isFooter}
      isActive={isActive}
      onClick={onRowClicked}
    >
      <StyledRank isActive={isActive}>
        {activePickem?.status === "APPROVED" ||
        (isContestClosed && !isCompleted)
          ? "-"
          : row?.rank}
      </StyledRank>
      <Avatar src={row?.user?.image} />
      <StyledUserName isActive={isActive}>{row?.user.username}</StyledUserName>
      {hasScores &&
      (!isContestClosed || isCompleted) &&
      (!isShowingMyBet || !isFooter) ? (
        <StyledResults>
          <StyledResultsIcon isSuccess isActive={isActive}>
            check_circle
          </StyledResultsIcon>
          <StyledResultsValue isActive={isActive}>
            {row?.points}
          </StyledResultsValue>
          <StyledResultsIcon isDanger isActive={isActive}>
            cancel
          </StyledResultsIcon>
          <StyledResultsValue isActive={isActive}>
            {startedQuestions - row?.points}
          </StyledResultsValue>

          {/* {totalQuestions !== startedQuestions && (
            <>
              <StyledResultsIcon isDefault isActive={isActive}>
                panorama_fish_eye
              </StyledResultsIcon>
              <StyledResultsValue isActive={isActive}>
                {totalQuestions - startedQuestions}
              </StyledResultsValue>
            </>
          )} */}
        </StyledResults>
      ) : null}
      {hasScores && isShowingMyBet && isFooter && (
        <StyledStopFilteringMyBetButton
          onClick={handleStopFilteringMyBetClicked}
        >
          <MatIcon size="18px">close</MatIcon>
          <span style={{ marginLeft: "0.325rem" }}>Stop Filtering</span>
        </StyledStopFilteringMyBetButton>
      )}
    </StyledWrapper>
  );
};

export default LeaderboardListRowHeader;
