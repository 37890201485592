import React from "react";
import styled from "styled-components";
import history from "router/history";
import { toast } from "react-toastify";
import { useTranslations } from "hooks";
import { handleError } from "utils";
import { LegalFooter } from "components/shared";
import { ChangePassword as AppChangePassword } from "@low6dev/low6-private/dist";

const StyledWrapper = styled.div`
  padding-top: 1rem;
`;

const Wrapper = () => {
  const [translations] = useTranslations();

  const handleSuccess = () => {
    history.push("/profile");
    toast.success("Password changed");
  };

  const gotoProfile = () => {
    history.push("/profile");
  };

  return (
    <StyledWrapper>
      <AppChangePassword
        translations={translations}
        onError={handleError}
        onSuccess={handleSuccess}
        onBackClicked={() => gotoProfile()}
      />
      <LegalFooter />
    </StyledWrapper>
  );
};

export default Wrapper;
